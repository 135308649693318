import React, { useState, useEffect } from 'react'
import { Card, CardBody, Row, Table } from 'reactstrap';
import AdminPagination from './Components/AdminPagination'
import { useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import './configration.scss'
import './datatables.scss'
import './specification.scss'
import './AddInnerPageResource.scss'
import ReactDragListView from "react-drag-listview/lib/index.js"


const ArticleSteps = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const sizePerPage = 10;
    const [page, setPage] = useState(1)
    const [totalSize, setTotalSize] = useState("10")
    const [filter, setFilter] = useState(null)
    const [data, setData] = useState([])
    
    

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
      },
      {
        dataField: "image",
        text: "Image",
        sort: true,
      },
      {
        dataField: "MainCategory",
        text: "Category",
        sort: true,
      },
      {
        dataField: "Category",
        text: "SubCategory",
        sort: true,
      },
      {
        dataField: "nameEn",
        text: "Title (English)",
        sort: true,
      },
      {
        dataField: "Viwers",
        text: "Views",
        sort: true,
      },
      {
        dataField: "Links",
        text: "$ Of Links",
        sort: true,
      },
      {
        dataField: "Videos",
        text: "$ Of Videos",
        sort: true,
      },
  
      {
        dataField: "DownloadFiles",
        text: "$ Of Files",
        sort: true,
      },
  
      {
        dataField: "actions",
        text: "Actions",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Created On",
        sort: true,
      },
    ]

    function deleteArticle(id) {
        axios.post(`${process.env.REACT_APP_API}/dashboard/uxProcess/configurations/subCategory/delete`,{id},{
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
        }).then(res => {
            toast.success(res.data.message)
            setData(data => data.filter(d => d.id !== id))
        })
    }

    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const formData = new FormData()
        const modifiedData = [...data]
        const item = modifiedData.splice(fromIndex, 1)[0]
        modifiedData.splice(toIndex, 0, item)
        setData(modifiedData)
  
        modifiedData.forEach((prod,i) => {
          formData.append(`ids[${i}]`, prod.id)
        })
  
        axios
        .post(
          `${process.env.REACT_APP_API}/dashboard/uxProcess/configurations/subCategory/arrange`, formData,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
          }
        )
        .then(res => {
          toast.success(res.data.message, {position: "top-right", theme:"light"})
        })
      },
      nodeSelector: "tr",
      handleSelector: "tr",
    }
  


    useEffect(() => {
        dispatch(setTitle("Article Steps"))
        axios.get(`${process.env.REACT_APP_API}/dashboard/uxProcess/configurations/subCategory`,{
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }).then(res => {
          setData(res.data.data.map( data => {
            return (
                {...data,
                    image: <img src={data.icon} style={imageStyle} alt=""/>,
                    Category:<span  style={{backgroundColor: 'rgb(216 216 216)',display: "block",
                    color: 'rgb(0, 0, 0)',
                    borderRadius: '45px',
                    padding: '5px 20px',
                    display: 'inline-block'}}>{data.Category}</span>,
                    MainCategory:<span  style={{backgroundColor: 'rgb(216 216 216)',display: "block",
                    color: 'rgb(0, 0, 0)',
                    borderRadius: '45px',
                    padding: '5px 20px',
                    display: 'inline-block'}}>{data.MainCategory}</span>,
                    actions: (
                        <div className="d-flex">
                          <button
                            style={editBtn}
                            onClick={() => history.push(`/uiux/article-steps/article-form?itemId=${data.nameEn}`)}
                          >
                            <i
                              className="bx bx-edit fs-4 pr-1"
                              style={{ marginRight: "10px" }}
                            ></i>{" "}
                            Edit
                          </button>
                          <button
                            style={deleteBtn}
                            onClick={e => deleteArticle(data.id)}
                          >
                            <i
                              className="bx bx-trash fs-4"
                              style={{ marginRight: "10px" }}
                            ></i>{" "}
                            Delete
                          </button>
                        </div>
                      ),
                }
            )
          }));
        })
    }, [])


  return (
    <>
        <Card>
            <CardBody>
                sd
            </CardBody>
        </Card>
        {/* <Card>
            <CardBody>
                <AdminPagination 
                    data={data} 
                    page={page}
                    setFilter={setFilter}
                    keyFiled='id'
                    sizePerPage={sizePerPage} 
                    totalSize={totalSize}
                    url=''
                    none={false}
                    onTableChange={(type, { page }) => {
                        setPage(page)
                    }} 
                    columns={columns}
                />
            </CardBody>
        </Card> */}
        <Card>
                <CardBody className="pb-0 mb-5">
                <Row>
                  <div className="save__btn top mb-4">
                    <button onClick={() =>  history.push('/uiux/article-steps/article-form?itemId')}>
                      <i className="bx bx-plus-circle fs-4" /> Add Atricle Steps
                    </button>
                  </div>
                </Row>
                <div className="table-responsive">
                  <ReactDragListView {...dragProps} enableScroll={true}>
                    <Table className="table  mb-0">
                      <thead>
                        <tr>
                          <th style={tdStyle}>ID</th>
                          <th style={tdStyle}>Image</th>
                          <th style={tdStyle}>MainCategory</th>
                          <th style={tdStyle}>Category</th>

                          <th style={tdStyle}>Title (English)</th>
                          <th style={tdStyle}>Viwers</th>
                          <th style={tdStyle}># Of Links</th>
                          <th style={tdStyle}># Of Videos</th>
                          <th style={tdStyle}># Of Files</th>
                          <th style={tdStyle}>ِActions</th>
                          <th style={tdStyle}>Created On</th>
                        </tr>
                      </thead>
                      <tbody className="table-responsive">
                        {(data || [])?.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>{item.id}</td>
                            <td style={tdStyle}>{item.image}</td>
                            <td style={tdStyle}>{item.MainCategory}</td>
                            <td style={tdStyle}>{item.Category}</td>
                            <td style={tdStyle}>{item.nameEn}</td>
                            <td style={tdStyle}>{item.Viwers}</td>

                            <td style={tdStyle}>{item.Links}</td>
                            <td style={tdStyle}>{item.Videos}</td>
                            <td style={tdStyle}>{item.DownloadFiles}</td>
                            <td style={tdStyle}>{item.actions}</td>
                            <td style={tdStyle}>{item.created_at}</td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </ReactDragListView>
                  </div>
                </CardBody>
              </Card>
    </>
    
  )
}

export default ArticleSteps

 const deleteBtn = {
    background: "transparent",
    border: "none",
    color: "#D91414",
    display: "flex",
    alignItems: "center",
    gap: "0px",
  }
  
  const editBtn = {
    background: "transparent",
    border: "none",
    display: "flex",
    alignItems: "center",
    gap: "0px",
  }
  
  const imageStyle = {
    width: "60px",
    height: "60px",
    display: "block",
    objectFit: "contain",
  }
  const tdStyle = { 
    whiteSpace: "nowrap",
    verticalAlign: "middle"
  }
 