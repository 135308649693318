import React, { useState, useEffect } from "react"
import { Row, Col, Form, Label, Input, Card } from "reactstrap"
import ReactLoading from "react-loading"
import axios from "axios"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"


const HeaderContentForMain = () => {
  const [active, setActive] = useState(false)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [subTitleEn, setSubTitleEn] = useState("")
  const [subTitleAr, setSubTitleAr] = useState("")
  const [subDescEn, setSubDescEn] = useState("")
  const [subDescAr, setSubDescAr] = useState("")
  const [image, setImage] = useState(null)
  const [images, setImages] = useState([])
  const [imageForMobile, setImageForMobile] = useState(null)
  const [imagesForMobile, setImagesForMobile] = useState([])
  const [prev, setPrev] = useState(null)
  const [isOpen, setisOpen] = useState(false)

  function handleImage(e, files, type) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    type == "vector" && setImage(Object.values(files)[0])
    type == "images" && setImages(images => images.concat(Object.values(files)))
    type == "imageForMobile" && setImageForMobile(Object.values(files)[0])
    type == "imagesForMobile" &&
      setImagesForMobile(imagesForMobile =>
        imagesForMobile.concat(Object.values(files))
      )
    e.target.value = ""
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }
  function submit(e) {
    e.preventDefault()
    setActive(true)
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
   
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/blog/staticPages/mainHeaderContent`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setActive(false)
        toast.success("Success Updated", {position: "top-right"})
        getData()
      })
      .catch(err => {
        setActive(false)
        toast.error("Something went wrong")
      })
  }

  

  function getData() {
    axios
      .get(`${process.env.REACT_APP_API}/dashboard/blog/staticPages/mainHeaderContent`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        let data = res.data.data
        setTitleEn(data?.titleEn)
        setTitleAr(data?.titleAr)
        setDescEn(data?.descriptionEn)
        setDescAr(data?.descriptionAr)
        data?.image && setImage({ preview: data?.image })
      })
      .catch(err => {})
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div>
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 pb-2">
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Main Title (english)</Label>
              <Input
                id="itemnameen"
                name="titleEn"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Main Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="titleAr"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                name="describtionEn"
                className="form-control  mb-0"
                id="specification"
                rows="7"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                name="describtionAr"
                className="form-control  mb-0"
                id="specification"
                rows="7"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        
        
        
       
      </Form>
      <Row className="mt-2">
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button onClick={submit}>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HeaderContentForMain
