import React, { useEffect, useState, useMemo } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import { useDispatch, useSelector } from "react-redux"
import { setTitle } from "../../../../store/actions"
import BodySection from "../Components/Pages/BodySection"
import StepsInforamtion from "../Components/Pages/StepsInforamtion"
import Links from "../Components/Pages/Links"
import Viedos from "../Components/Pages/Viedos"
import DownloadFile from "../Components/Pages/DownloadFile"
import axios from "axios"
import { useHistory, useLocation, useParams } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import Seo from "../Components/Pages/Seo"



const UxOptions = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [tags, setTags] = useState([])
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [id, setId] = useState(null) 
  const [fontSize, setFontSize] = useState([])
  const [fontType, setFontType] = useState([])
  const query = useQuery()
  const [data, setData] = useState(null)

  const [prev, setPrev] = useState(null);
  const [isOpen, setisOpen] = useState(false)

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  function prevSelectImage(prev) {
    setPrev(prev)
    setisOpen(!isOpen)
  }

  useEffect(() => {
      dispatch(setTitle("UI/UX Configurations"))
      axios.get(`${process.env.REACT_APP_API}/dashboard/uxProcess/subCategory/fontSize`,{
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res => {
        setFontSize(res.data.data);
      })

      axios.get(`${process.env.REACT_APP_API}/dashboard/uxProcess/subCategory/fontType`,{
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res => {
        setFontType(res.data.data);
      })
  }, [])

  let getData = () => {
    axios.get(`${process.env.REACT_APP_API}/dashboard/uxProcess/configurations/subCategory?search=${id}`,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
        setData(res.data.data)
        setId(res.data.data.id)
    })
  }

  useEffect(() => {
    let itemId  = query.get('itemId')
    itemId&&axios.get(`${process.env.REACT_APP_API}/dashboard/uxProcess/configurations/subCategory?search=${itemId}`,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
        setData(res.data.data)
        setId(res.data.data.id)
        console.log(res.data.data)
    })
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Articles Steps | Ux-Resources</title>
        </MetaTags>
        <Container fluid>
        {isOpen ? (
          <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
              setisOpen(!isOpen);
              }}
          />
          ) : null} 
          <Col>
            <Row>
              <Col md="3">
                <Sticky topOffset={-300} >
                  <Nav
                    pills
                    className="flex-column justify-content-between navs_aside"
                  >
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Step Information
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item">
                      <NavLink 
                        // disabled={id?false:true}
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "disable": true,
                          active: verticalActiveTab === "2",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        Body Section
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item">
                      <NavLink
                        // disabled={id?false:true}

                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "disable": true,
                          active: verticalActiveTab === "3",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("3")
                        }}
                      >
                        Links
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item">
                      <NavLink
                        // disabled={id?false:true}

                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "disable": true,
                          active: verticalActiveTab === "4",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("4")
                        }}
                      >
                        Videos
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav___item">
                      <NavLink
                        // disabled={id?false:true}

                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "disable": true,
                          active: verticalActiveTab === "5",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("5")
                        }}
                      >
                        Download Files
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "disable": true,
                          active: verticalActiveTab === "6",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("6")
                        }}
                      >
                        Seo
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Sticky>
              </Col>
              <Col md="9">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <StepsInforamtion setId={setId} id={id} itemData={data}  getData={getData} prevSelectImage={prevSelectImage}/>
                  </TabPane>
                  <TabPane tabId="2">
                    <BodySection modulesQuill={modulesQuill} formatsQuill={formatsQuill} id={id} prevSelectImage={prevSelectImage} font={{size: fontSize, type: fontType}} itemData={data} getData={getData}/>
                  </TabPane>
                  <TabPane tabId="3">
                    <Links id={id} itemData={data} getData={getData}/>
                  </TabPane>
                  <TabPane tabId="4">
                    <Viedos id={id} itemData={data} getData={getData}/>
                  </TabPane>
                  <TabPane tabId="5">
                    <DownloadFile id={id} itemData={data} getData={getData}/>
                  </TabPane>
                  <TabPane tabId="6">
                    <Seo id={id} itemData={data} getData={getData}/>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
        <Row className="submit__btn footer fixed fixed-bottom">
                <Col lg={10} md={10} className='d-flex'>
                <button type='button' className="cancel mr-3" onClick={() => history.goBack()}>
                    cancel
                </button>
                {" "}
                
                </Col>
          </Row>
      </div>
    </React.Fragment>
  )
}

export default UxOptions


const modulesQuill = {
  toolbar: {
    container: [
      [{ "header": [1, 2, 3, 4, 5, 6, false] }],
      [{ "font": [] }],
      [{ "align": [] }],
      ["bold", "italic", "underline"],
      [{ "list": "ordered" }, { "list": "bullet" }, { "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", "custom-color"] }, { "background": [] }, "link", "emoji"]
    ],
    handlers: {
      "color": function(value) {
        if (value === "custom-color") value = window.prompt("Enter Hex Color Code")
        this.quill.format("color", value)
      }
    }
  },
  keyboard: {
    bindings: {
      tab: false,
      custom: {
        key: 13,
        shiftKey: true,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      },
      handleEnter: {
        key: 13,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      }
    }
  }
}

const formatsQuill = [
  "header", "font", "size",
  "bold", "italic", "underline", "strike", "blockquote",
  "list", "bullet", "indent", "align",
  "link", "image", "background", "color", "emoji"
]