import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {Link} from "react-router-dom"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import './specification.scss'


const EmailForm = (props) => {
    let p = props 
    const [isOpen, setisOpen] = useState(false);
    const [titleEn, setTitleEn] = useState('');
    const [titleAr, setTitleAr] = useState('');
    const [subtitleEn, setSubTitleEn] = useState('');
    const [subtitleAr, setSubTitleAr] = useState('');
    const [slogan, setSlogan] = useState('');
    const [email, setEmail] = useState('');
    const [adLink, setAdLink] = useState('');
    const [termsLink, setTermsLink] = useState('');
    const [covidLink, setCovidLink] = useState('');
    const [pointPolicyLink, setPointPolicyLink] = useState('');
    const [photoGuidelink,setPhotoGuidelink] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [descriptionEn, setDiscriptionEn] = useState('');
    const [descriptionAr, setDiscriptionAr] = useState('');
    const [descriptionFooterEn, setDescriptionFooterEn] = useState('')
    const [descriptionFooterAr, setDescriptionFooterAr] = useState('')
    const [supportTextEn, setSupportTextEn] = useState('')
    const [supportTextAr, setSupportTextAr] = useState('')
    const [image, setImage] = useState(null)
    const [addImage, setAddImage] = useState(null)
    const [logo, setLogo] = useState(null)
    const [color, setColor] = useState('');
    const [prev, setPrev] = useState(null)

    const [loading, setLoading] = useState(false)
    
    const nameEnRef = useRef(null);
    const nameArRef = useRef(null);
    const descEnRef = useRef(null);
    const descArRef = useRef(null);
    const descFootEnRef = useRef(null);
    const descFootArRef = useRef(null);
    const imageRef = useRef(null);
    const colorRef = useRef(null);
    const logoRef = useRef(null);

    function handleAcceptedFiles(files, type,e) {
        e.target.value = ""
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )
        type === "logo"?setLogo(files[0]):type === "add"?setAddImage(files[0]):setImage(files[0]) 
    }


    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    function setOpen(e, type) {
        e.preventDefault() 
        type === "logo"? setPrev(logo.preview): type === "add"?setPrev(addImage.preview):setPrev(image.preview)
        setisOpen(true)
    }

    function submitHandler() {
        setLoading(true)
        const formData = new FormData();
        
        let data = {
            titleEn,
            titleAr,
            subtitleEn,
            subtitleAr,
            email,
            descriptionEn,
            descriptionAr,
            footerEn: descriptionFooterEn,
            footerAr: descriptionFooterAr,
            supportTextEn,
            supportTextAr,
            facebook,
            instagram,
            addLink: adLink,
            covidLink,
            termsLink,
            pointPolicyLink,
            photoGuidelink,
            color,
            slogan
        } 
        
        for ( let key in data ) {
            formData.append(key, data[key]);
        }
        
        if(image && image.name) {
            formData.append("image", image);
        }
        if(logo && logo.name) {
            formData.append("logo", logo);
        }
        if(addImage && addImage.name) {
            formData.append("addImage", addImage);
        }


        axios.post(`${process.env.REACT_APP_API}/dashboard/uxProcess/configurations/emailShot`, formData, {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
          })
        .then(response => {
            let res = response.data;
            if(res.error) {
                setLoading(false)
                return;
            }
            setLoading(false)
            p.clickHandle()
        })
        .catch(function (error) {
            console.log(error);
        });
    }


    useEffect(() => {
        if(p.data) {
            setTitleEn(p.data.titleEn||'')
            setTitleAr(p.data.titleAr||'')
            setSubTitleEn(p.data.subtitleEn||'')
            setSubTitleAr(p.data.subtitleAr||'')
            setDiscriptionEn(p.data.descriptionEn||'')
            setDiscriptionAr(p.data.descriptionAr||'')
            setDescriptionFooterEn(p.data.footerEn||'')
            setDescriptionFooterAr(p.data.footerAr||'')
            setSupportTextEn(p.data.supportTextEn||'')
            setSupportTextAr(p.data.supportTextAr||'')
            setEmail(p.data.email||'')
            setFacebook(p.data.facebook||'')
            setInstagram(p.data.instagram||'')
            setColor(p.data.color||'')
            setLogo({preview: p.data.logo}||null)
            setImage({preview: p.data.image}||null)
            setAddImage({preview: p.data.addImage}||null)
            setAdLink(p.data.addLink||'')
            setCovidLink(p.data.covidLink||'')
            setTermsLink(p.data.termsLink||'')
            setPointPolicyLink(p.data.pointPolicyLink||'')
            setPhotoGuidelink(p.data.photoGuidelink||'')
            setSlogan(p.data.slogan||'')
            return;
        }
    }, [p.data])


    return(
        <div className={`overlay_coup ${p.active? "active":""}`}>
            {isOpen ? (
            <Lightbox
                mainSrc={prev}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
            <Form onSubmit={(e) => e.preventDefault()} style={{paddingBottom: "70px", height: "500px"}}>
                <div className='head_form'>
                    <div className='log'>
                    <i className='bx bx-envelope fs-4'></i>
                        Email Shot Edit
                    </div>
                    <button onClick={() => p.clickHandle()}><i className='dripicons dripicons-cross'></i></button>
                </div>
                <div className='inputs__wrap '>
                    <Row>
                            <Col sm="12" className="mb-1">
                                <Col>
                                    <div className="mb-1">
                                        <Label htmlFor="metatitle" className="req">Logo</Label>
                                        <div className="mb-3" ref={logoRef}>
                                            <Input 
                                                type="file"
                                                className="form-control form-control-lg"
                                                onChange={e => handleAcceptedFiles(Object.values(e.target.files), "logo",e)}
                                                style={{borderRadius: 0}}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                    </div>
                                </Col>
                                {logo? <Row className="align-items-center mb-2">
                                    <Col className="col-auto ">
                                        <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={logo.name}
                                        src={logo.preview}
                                        style={{objectFit: "cover"}}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                        to="#"
                                        className="text-muted "
                                        >
                                        {logo.name}
                                        </Link>
                                        <p className="mb-0">
                                        <strong>{logo.formattedSize}</strong>
                                        </p>
                                    </Col>
                                    <Col className=''>
                                        <div className="image__options">
                                            <button className="p-0"  onClick={(e) => setOpen(e,"logo")}><i className="far fa-eye  fs-5 p-0"></i></button>
                                            <button className='p-0' style={{color: "#ff3838"}} onClick={(e) =>{e.preventDefault(), setLogo(null)}}><i className='bx bx-trash fs-4'></i></button>
                                        </div>
                                    </Col>
                                </Row>:null}
                            </Col>
                        <Col>
                            <div className="mb-3" ref={nameEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Title (English)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={titleEn}
                                    onChange= {e => setTitleEn(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameArRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Title (Arabic)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="مثال. عنوان"
                                    dir='rtl'
                                    value={titleAr}
                                    onChange= {e => setTitleAr(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Sub Title (English)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={subtitleEn}
                                    onChange= {e => setSubTitleEn(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameArRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Sub Title (Arabic)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="مثال. عنوان"
                                    dir='rtl'
                                    value={subtitleAr}
                                    onChange= {e => setSubTitleAr(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Col sm="12">
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="metatitle" className="req">Image</Label>
                                        <div className="mb-3" ref={imageRef}>
                                            <Input 
                                                type="file"
                                                className="form-control form-control-lg"
                                                onChange={e => handleAcceptedFiles(Object.values(e.target.files), "IMAGE",e)}
                                                style={{borderRadius: 0}}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                    </div>
                                </Col>
                                {image? <Row className="align-items-center mb-2">
                                    <Col className="col-auto">
                                        <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={image.name}
                                        src={image.preview}
                                        style={{objectFit: "cover"}}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                        to="#"
                                        className="text-muted "
                                        >
                                        {image.name}
                                        </Link>
                                        <p className="mb-0">
                                        <strong>{image.formattedSize}</strong>
                                        </p>
                                    </Col>
                                    <Col className=''>
                                        <div className="image__options">
                                            <button className="p-0"  onClick={(e) => setOpen(e,"image")}><i className="far fa-eye  fs-5 p-0"></i></button>
                                            <button className='p-0' style={{color: "#ff3838"}} onClick={(e) =>{e.preventDefault(), setImage(null)}}><i className='bx bx-trash fs-4'></i></button>
                                        </div>
                                    </Col>
                                </Row>:null}
                        </Col>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={descEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Description (English)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={descriptionEn}
                                    onChange= {e => setDiscriptionEn(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={descArRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Description (Arabic)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="مثال. عنوان"
                                    dir='rtl'
                                    value={descriptionAr}
                                    onChange= {e => setDiscriptionAr(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={descArRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Slogan
                                </Label>
                                <Input
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={slogan}
                                    onChange= {e => setSlogan(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <div className="mb-3" ref={descFootEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                Support Text (English)
                                </Label>
                                <Input
                                    name="text"
                                    type="textarea"
                                    value={supportTextEn}
                                    onChange={(e) => setSupportTextEn(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={descFootArRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Support Text(Arabic)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="textarea"
                                    className="form-control input_cus"
                                    placeholder="مثال. عنوان"
                                    dir='rtl'
                                    value={supportTextAr}
                                    onChange={(e) => setSupportTextAr(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    email
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={email}
                                    onChange= {e => setEmail(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Facebook Link
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={facebook}
                                    onChange= {e => setFacebook(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Instagram Link
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={instagram}
                                    onChange= {e => setInstagram(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <Label htmlFor="metatitle">Color</Label>
                                <div className="d-flex mb-3" ref={colorRef}>
                                    <Input 
                                        type="color"
                                        value={color}
                                        onChange={e => setColor(e.target.value)}
                                        style={{width: "70px", boxShadow: "none"}}
                                    />
                                    <Input 
                                        type="text" 
                                        className="color__name pl-0"
                                        style={{borderRadius: 0}}
                                        value={color}
                                        onChange={e => setColor(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    
                    <Row>
                            <Col sm="12">
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="metatitle" className="req">Ad Image</Label>
                                        <div className="mb-3" ref={imageRef}>
                                            <Input 
                                                type="file"
                                                className="form-control form-control-lg"
                                                onChange={e => handleAcceptedFiles(Object.values(e.target.files), "add",e)}
                                                style={{borderRadius: 0}}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                    </div>
                                </Col>
                                {addImage? <Row className="align-items-center mb-2">
                                    <Col className="col-auto">
                                        <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={addImage.name}
                                        src={addImage.preview}
                                        style={{objectFit: "cover"}}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                        to="#"
                                        className="text-muted "
                                        >
                                        {addImage.name}
                                        </Link>
                                        <p className="mb-0">
                                        <strong>{addImage.formattedSize}</strong>
                                        </p>
                                    </Col>
                                    <Col className=''>
                                        <div className="image__options">
                                            <button className="p-0"  onClick={(e) => setOpen(e,"add")}><i className="far fa-eye  fs-5 p-0"></i></button>
                                            <button className='p-0' style={{color: "#ff3838"}} onClick={(e) =>{e.preventDefault(), setAddImage(null)}}><i className='bx bx-trash fs-4'></i></button>
                                        </div>
                                    </Col>
                                </Row>:null}
                        </Col>            
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Ad Link
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={adLink}
                                    onChange= {e => setAdLink(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Terms Link
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={termsLink}
                                    onChange= {e => setTermsLink(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Covid Link
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={covidLink}
                                    onChange= {e => setCovidLink(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Point Policy Link
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={pointPolicyLink}
                                    onChange= {e => setPointPolicyLink(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={nameEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Photo Guide Link
                                </Label>
                                <Input
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={photoGuidelink}
                                    onChange= {e => setPhotoGuidelink(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={descFootEnRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Description Footer (English)
                                </Label>
                                <Input
                                    name="text"
                                    type="textarea"
                                    value={descriptionFooterEn}
                                    onChange={(e) => setDescriptionFooterEn(e.target.value)}
                                    row={7}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" ref={descFootArRef}>
                                <Label htmlFor="productdesc" className="req">
                                    Description Footer(Arabic)
                                </Label>
                                <Input
                                    name="title"
                                    type="textarea"
                                    className="form-control input_cus"
                                    placeholder="مثال. عنوان"
                                    dir='rtl'
                                    value={descriptionFooterAr}
                                    onChange={(e) => setDescriptionFooterAr(e.target.value)}
                                    row={7}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='foot_form'>
                    <button className='cancel' onClick={() => p.clickHandle()}>Cancel</button>
                    <button className='save' onClick={submitHandler}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Save"}</button>
                </div>
            </Form>
        </div>
    );
};

export default EmailForm;