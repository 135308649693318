import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Input, Label, Row, FormFeedback } from 'reactstrap'
import ImagesForm from '../BodySection/ImagesForm'
import ImportantParagraphForm from '../BodySection/ImportantParagraphForm'
import ParagraphForm from '../BodySection/ParagraphForm'
import TitlesForm from '../BodySection/TitlesForm'
import ReactLoading from "react-loading"
import Tabs from '../BodySection/Tabs'
import prettyBytes from 'pretty-bytes'
import { Link } from 'react-router-dom'
import Select from "react-select"
import axiosConfig from 'axiosConfig'
import axios from 'axios'
import { toast } from 'react-toastify'

const DataSchema = { 
  title: {
    titleEn: "",
    titleAr: "",
    type: "Title"
  },
  description: {
    descriptionEn: "",
    descriptionAr: "",
    type: "Paragraph" 
  },
  importantDescription: {
    importantDescriptionEn: "",
    importantDescriptionAr: "",
    color: "",
    type: "ImportantParagraph" 
  },
  image: {
    image: null,
    type: "Images" 
  }
}

const StepsInforamtion = ({setId,itemData,id, prevSelectImage}) => {
  const [active, setActive] = useState(false)
  const [data, setData] = useState([])
  const [prev, setPrev] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [nameEn, setNameEn] = useState("")
  const [nameAr, setNameAr] = useState("")
  const [descriptionEn, setDescriptionEn] = useState("")
  const [descriptionAr, setDescriptionAr] = useState("")
  const [category, setCategory] = useState("")
  const [image, setImage] = useState(null)


  function handleFiles(e, files) {
      [...files].map(file =>
          Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: prettyBytes(file.size),
          })
      )
      setImage(Object.values(files)[0])
      e.target.value = ""
  }
      


  function submit () {
    setActive(true)
    let formData = new FormData()

    let data = { 
      nameEn,
      nameAr,
      descriptionEn,
      descriptionAr,
      icon: image,
      category:category.value,
      id
    }
    
    for (let key in data) {
      formData.append(key, data[key])
    }
    
    axios.post(`${process.env.REACT_APP_API}/dashboard/uxProcess/configurations/subCategory${id?"/edit":""}`, formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
    .then(res => {
      setActive(false)
      toast.success(res.data.message);
      setId(res.data.data.subCategoryID)
      
    })
  }

  let getCategories = () => {
    axiosConfig
      .get(`/dashboard/uxProcess/subCategory/mainCategories`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setCategories(
          res.data.data.map(cat => ({
            label: (
              <span
                className="fs-5 text-capitalize "
                style={{ color: "#1DB217" }}
              >
                {cat.nameEn}
              </span>
            ),
            options: cat.subCat.map(child => ({
              label: child.nameEn,
              value: child.id,
            })),
          }))
        )
      })
      .catch(err => {})
  }

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    if(itemData) { 
      setNameEn(itemData.nameEn)
      setNameAr(itemData.nameAr)
      setDescriptionEn(itemData.descriptionEn)
      setDescriptionAr(itemData.descriptionAr)
      itemData.Category&&setCategory({label: itemData.Category.nameEn, value: itemData.Category.id})
      setImage(itemData.icon)
    }
  }, [itemData])


  return (
    <>
      <Card>
          <CardBody>
              <Row className="mb-3">
                <Col>
                  <Label htmlFor="productdesc " className="">
                    Sub Category
                  </Label>
                  <Select
                    // isMulti={true}
                    name="categories"
                    value={category}
                    onChange={e => setCategory(e)}
                    options={categories}
                    // styles={{borderColor: errors.categories?"#f00f00 !important":null}}
                  />
                  <Input className="d-none" />
                  <FormFeedback className="fs-6">
                  </FormFeedback>
                </Col>
              </Row>
              <Row className="mt-3">
                  <Col xs="12">
                      <div>
                      <Label htmlFor="productdesc">Image</Label>
                      <Input
                          type="file"
                          className="form-control form-control-lg "
                          name='attachmentImage'
                          onChange={e => handleFiles(e, e.target.files)}
                      />
                      </div>
                  </Col>
                  <Col xs="12">
                      <div className="dropzone-previews" id="file-previews">
                      {image ? (
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-2">
                              <Row className="align-items-center">
                              <Col className="col-auto">
                                  <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={image.name}
                                  src={image.preview ?image.preview: image }
                                  style={{ objectFit: "cover" }}
                                  />
                              </Col>
                              <Col>
                                  <Link to="#" className="text-muted ">
                                  {image.name}
                                  </Link>
                              </Col>
                              <Col>
                                  <div className="image__options">
                                  <button
                                      className="p-0"
                                      onClick={e =>
                                      prevSelectImage(image.preview?image.preview:image)
                                  }
                                  >
                                      <i className="far fa-eye  fs-5 p-0"></i>
                                  </button>
                                  <button
                                      style={{ color: "#ff3838" }}
                                      onClick={e => setImage(null)}
                                  >
                                      <i className="bx bx-trash fs-4"></i>
                                  </button>
                                  </div>
                              </Col>
                              </Row>
                          </div>
                          </Card>
                      ) : null}
                      </div>
                  </Col>
              </Row>
              <Row className="mt-3">
                  <Col>
                      <div className="mb-3">
                          <Label htmlFor="productdesc">Title (English)</Label>
                          <Input
                              name="nameEn"
                              type="text"
                              className="form-control input_cus rounded"
                              placeholder="e.g.Name"
                              value={nameEn}
                              onChange={e => setNameEn(e.target.value)}
                          />
                      </div>
                  </Col>
                  <Col>
                      <div className="mb-3">
                          <Label htmlFor="productdesc">Title (Arabic)</Label>
                          <Input
                              name="nameEn"
                              type="text"
                              className="form-control  input_cus rounded"
                              placeholder="e.g.Name"
                              dir="rtl" 
                              value={nameAr}
                              onChange={e => setNameAr(e.target.value)}
                          />
                      </div>
                  </Col>
              </Row>
              <Row className="">
                  <Col>
                      <div className="mb-3">
                          <Label htmlFor="productdesc">Description (English)</Label>
                          <Input
                              name="nameEn"
                              type="textarea"
                              className="form-control rounded fs-5"
                              placeholder="e.g.Name"
                              rows="7"
                              value={descriptionEn}
                              onChange={e => setDescriptionEn(e.target.value)}
                          />
                      </div>
                  </Col>
                  <Col>
                      <div className="mb-3">
                          <Label htmlFor="productdesc">Description (Arabic)</Label>
                          <Input
                              name="nameEn"
                              type="textarea"
                              placeholder="e.g.Name"
                              className="form-control rounded fs-5"
                              dir="rtl" 
                              rows="7"
                              value={descriptionAr}
                              onChange={e => setDescriptionAr(e.target.value)}
                          />
                      </div>
                  </Col>
              </Row>
              {/*************Submit button*************/}
              <hr style={{ margin: "20px -20px 20px -20px" }} />
              <Row>
                <Col className="d-flex justify-content-end gap-2">
                  <div className="save__btn top m-0 ">
                    <button type="button" onClick={submit}>
                      {active ? (
                        <ReactLoading
                          type={"spin"}
                          color={"#ffffff"}
                          height={30}
                          width={30}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
          </CardBody>
      </Card>
    </>
  )
}

export default StepsInforamtion