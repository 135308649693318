import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
//i18n
import { withTranslation } from "react-i18next"
const SidebarContent = props => {
  const ref = useRef()
  const notifications = useSelector(state => state.Notifications)
  const MainData = useSelector(state => state.MainData)
  const [notify, setNotify] = useState({
    notifications: [],
    userCount: 0,
    toyCount: 0,
    notifyCount: 0,
    suggestionCount: 0,
    feedbackCount: 0,
    reviewCount: 0,
    reportCount: 0,
  })
  const user = useSelector(state => state.UserData.user)
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  useEffect(() => {
    setNotify(notifications)
  }, [notifications])

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("General")} </li>

            <li>
              <Link to="/dashboard">
                <i className="bx bxs-dashboard"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Dashboards")}
                </span>
              </Link>
            </li> */}
            <li className="menu-title">{props.t("General")} </li>
            <li>
              <Link to="/dashboard">
                <i className="bx bxs-dashboard"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Dashboard")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/users">
                <i className="bx bxs-user-circle"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Users")}
                </span>
              </Link>
            </li>
           
            <li className="menu-title">{props.t("Design Process")} </li>
            <li>
              <Link to="/uiux/article-steps">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Article Steps")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/uiux/Pages">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Static Pages")}
                </span>
              </Link>
            </li>

            <li>
              <Link to="/uiux/seo">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Seo")}
                </span>
              </Link>
            </li>


            <li>
              <Link to="/uiux/configurations">
                <i className="bx bx-cog"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Configurations")}
                </span>
              </Link>
            </li>



            {/**********************Articles*********************/}
            <li className="menu-title">{props.t("Blog Articles")} </li>
            <li>
              <Link to="/blogs">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>{props.t("Blog Articles")}</span>
              </Link>
            </li>
            <li>
              <Link to="/blogs/seo">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Blog SEO")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/blogs/Pages">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Blog Static Pages")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/blogs/subscription">
                <i className="bx bx-cog"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Blog Subscription")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/blogs/options">
                <i className="bx bx-cog"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Blog Configurations")}
                </span>
              </Link>
            </li>
            

            {/**********************Academy*********************/}
            <li className="menu-title">{props.t("Academy")} </li>
            <li>
              <Link to="/uilearn/pages">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>{props.t("UI Learn")}</span>
              </Link>
            </li>
            <li>
              <Link to="/uilearn/registration">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Registration")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/ebook/pages">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>{props.t("eBook")}</span>
              </Link>
            </li>
            <li>
              <Link to="/uxlearn/pages">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>{props.t("UX Learn")}</span>
              </Link>
            </li>

            
            {/**********************Settings*********************/}
            <li className="menu-title">{props.t("Settings")} </li>
            <li>
              <Link to="/adminsetting">
                <i className="bx bx-cog"></i>
                <span style={{ fontSize: "14px" }}>{props.t("Settings")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
