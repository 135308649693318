import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Input, Label, Row, FormFeedback } from 'reactstrap'
import ImagesForm from '../BodySection/ImagesForm'
import ImportantParagraphForm from '../BodySection/ImportantParagraphForm'
import ParagraphForm from '../BodySection/ParagraphForm'
import TitlesForm from '../BodySection/TitlesForm'
import ReactLoading from "react-loading"
import Tabs from '../BodySection/Tabs'
import prettyBytes from 'pretty-bytes'
import { Link } from 'react-router-dom'
import Select from "react-select"
import axiosConfig from 'axiosConfig'
import axios from 'axios'
import { toast } from 'react-toastify'

const DataSchema = { 
  title: {
    titleEn: "",
    titleAr: "",
    type: "Title"
  },
  description: {
    descriptionEn: "",
    descriptionAr: "",
    type: "Paragraph" 
  },
  importantDescription: {
    importantDescriptionEn: "",
    importantDescriptionAr: "",
    color: "",
    type: "ImportantParagraph" 
  },
  image: {
    image: null,
    type: "Images" 
  }
}

const StepsInforamtion = ({setId,itemData,id, prevSelectImage}) => {
  const [active, setActive] = useState(false)
  const [data, setData] = useState([])
  const [prev, setPrev] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [authors, setAuthhors] = useState([])
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [category, setCategory] = useState("")
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedAuthor, setSelectedAuthor] = useState({
    label:'Select',
    value:''
  })



  const [image, setImage] = useState(null)


  function handleFiles(e, files) {
      [...files].map(file =>
          Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: prettyBytes(file.size),
          })
      )
      setImage(Object.values(files)[0])
      e.target.value = ""
  }
      


  function submit () {
    
    setActive(true)
    let formData = new FormData()

    let data = { 
      titleEn,
      titleAr ,
      image: image,
      author:selectedAuthor.value,
      id
    }
    
    for (let key in data) {
      formData.append(key, data[key])
    }

    selectedCategories.forEach((category,i) => {
      formData.append(`categories[${i}]`, category.value)
    })
   

    
    axios.post(`${process.env.REACT_APP_API}/dashboard/blog${id?"/edit":""}`, formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
    .then(res => {
      setActive(false)
      toast.success(res.data.message);
      setId(res.data.data.blogID)
      
    })
  }

  let getCategories = () => {
    axiosConfig
      .get(`/dashboard/blog/configurations/category`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setCategories(res.data.data)
      })
      .catch(err => {})
  }
  
  let getAuthors = () => {
    axiosConfig
      .get(`/dashboard/blog/configurations/author`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setAuthhors(res.data.data)
      })
      .catch(err => {})
  }

  useEffect(() => {
    getCategories()
    getAuthors()
  }, [])

  useEffect(() => {
    if(itemData) { 
      setTitleEn(itemData.titleEn)
      setTitleAr(itemData.titleAr)
      setSelectedAuthor({
        label : itemData.Author.nameEn,
        value: itemData.Author.id
      })
      setSelectedCategories(itemData.Categories.map(category => ({label:category.nameEn  , value: category.id})))


      setImage(itemData.image)
    }
  }, [itemData])


  return (
    <>
      <Card>
          <CardBody>
            
             
              <Row className="mt-3">
                  <Col xs="12">
                      <div>
                      <Label htmlFor="productdesc">Image</Label>
                      <Input
                          type="file"
                          className="form-control form-control-lg "
                          name='attachmentImage'
                          onChange={e => handleFiles(e, e.target.files)}
                      />
                      </div>
                  </Col>
                  <Col xs="12">
                      <div className="dropzone-previews" id="file-previews">
                      {image ? (
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-2">
                              <Row className="align-items-center">
                              <Col className="col-auto">
                                  <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={image.name}
                                  src={image.preview ?image.preview: image }
                                  style={{ objectFit: "cover" }}
                                  />
                              </Col>
                              <Col>
                                  <Link to="#" className="text-muted ">
                                  {image.name}
                                  </Link>
                              </Col>
                              <Col>
                                  <div className="image__options">
                                  <button
                                      className="p-0"
                                      onClick={e =>
                                      prevSelectImage(image.preview?image.preview:image)
                                  }
                                  >
                                      <i className="far fa-eye  fs-5 p-0"></i>
                                  </button>
                                  <button
                                      style={{ color: "#ff3838" }}
                                      onClick={e => setImage(null)}
                                  >
                                      <i className="bx bx-trash fs-4"></i>
                                  </button>
                                  </div>
                              </Col>
                              </Row>
                          </div>
                          </Card>
                      ) : null}
                      </div>
                  </Col>
              </Row>
              
              <Row className="mt-3">
                  <Col>
                      <div className="mb-3">
                          <Label htmlFor="productdesc">Title (English)</Label>
                          <Input
                              name="titleEn"
                              type="textarea"
                              className="form-control rounded fs-5"
                              placeholder="e.g.Name"
                              rows="7"
                              value={titleEn}
                              onChange={e => setTitleEn(e.target.value)}
                          />
                      </div>
                  </Col>
                  <Col>
                      <div className="mb-3">
                          <Label htmlFor="productdesc">Title (Arabic)</Label>
                          <Input
                              name="titleAr"
                              type="textarea"
                              placeholder="e.g.Name"
                              className="form-control rounded fs-5"
                              dir="rtl" 
                              rows="7"
                              value={titleAr}
                              onChange={e => setTitleAr(e.target.value)}
                          />
                      </div>
                  </Col>
              </Row>
              <Row  className="mb-3"> 
                    <Col >
                        <Label htmlFor="productdesc " className="req">
                            Categories
                        </Label>
                        <Select
                            isMulti={true}
                            name="categories"
                            closeMenuOnSelect={false}
                            value={selectedCategories}
                            onChange={e => setSelectedCategories(e)}
                            options={categories?.map(category => {
                              return {
                                  label:category.nameEn,
                                  value:category.id

                              }
                          }).flat()}
                            className="select2-selection"
                        />
                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>                          
                    </Col>      
                </Row>
                
                <Row  className="mb-3"> 
                    <Col >
                        <Label htmlFor="productdesc " className="req">
                            Author
                        </Label>
                        <Select
                            name="author"
                            closeMenuOnSelect={false}
                            value={selectedAuthor}
                            onChange={e => setSelectedAuthor(e)}
                            options={authors?.map(author => {
                              return {
                                  label:author.nameEn,
                                  value:author.id

                              }
                          }).flat()}
                            className="select2-selection"
                        />
                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>                          
                    </Col>      
                </Row>
              {/*************Submit button*************/}
              <hr style={{ margin: "20px -20px 20px -20px" }} />
              <Row>
                <Col className="d-flex justify-content-end gap-2">
                  <div className="save__btn top m-0 ">
                    <button type="button" onClick={submit}>
                      {active ? (
                        <ReactLoading
                          type={"spin"}
                          color={"#ffffff"}
                          height={30}
                          width={30}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
          </CardBody>
      </Card>
    </>
  )
}

export default StepsInforamtion