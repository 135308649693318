import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Collapse, Button
  } from "reactstrap"
  import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import prettyBytes from "pretty-bytes"
import Lightbox from "react-image-lightbox"
import axios from "axios"
import ReactTagInput from "@pathofdev/react-tag-input"
import { toast } from "react-toastify"
const SeoComponent = ({page, data}) => {
  const [active, setActive] = useState(false)
  const [seoKeyWordsEn, setSeoKeyWordsEn] = useState([])
  const [seoKeyWordsAr, setSeoKeyWordsAr] = useState([])
  const [seoFocusAr, setSeoFocusAr] = useState("")
  const [seoTitleEn, setSeoTitleEn] = useState("")
  const [seoTitleAr, setSeoTitleAr] = useState("")
  const [seoDescEn, setSeoDescEn] = useState("")
  const [seoDescAr, setSeoDescAr] = useState("")
  const [seoSlugEn, setSeoSlugEn] = useState("")
  const [seoSlugAr, setSeoSlugAr] = useState("")
  const [seoFaceTitleEn, setSeoFaceTitleEn] = useState("")
  const [seoFaceTitleAr, setSeoFaceTitleAr] = useState("")
  const [seoFaceDescEn, setSeoFaceDescEn] = useState("")
  const [seoFaceDescAr, setSeoFaceDescAr] = useState("")
  const [facebookImage, setFacebookImage] = useState(null)
  const [image, setImage] = useState(null)
  const [prev, setPrev] = useState(null);
  const [isOpen, setisOpen] = useState(false)

  function handleFacebookFiles(e, files, type) {
    [...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    type == "face" && setFacebookImage(Object.values(files)[0])
    type == "image" && setImage(Object.values(files)[0])
    e.target.value = ""
  }
  
  function deleteSelectImage(type){
    setFacebookImage(null)
  }

  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }



  function submit() {
    let formData = new FormData()
    setActive(true)
    let data = { 
      titleEn:seoTitleEn,
      titleAr: seoTitleAr,
      descriptionEn: seoDescEn,
      descriptionAr: seoDescAr,
      slugEn: seoSlugEn,
      slugAr: seoSlugAr,
      facebookTitleEn: seoFaceTitleEn,
      facebookTitleAr: seoFaceTitleAr,
      facebookDescriptionEn: seoFaceDescEn, 
      facebookDescriptionAr: seoFaceDescAr, 
      facebookImage,
      image 
    }

    for (let key in data) {
      formData.append(key, data[key])
    }
    seoKeyWordsEn.forEach((word,i ) => {
        formData.append(`keywordsEn[${i}]`, word)
    })
    seoKeyWordsAr.forEach((word,i ) => {
        formData.append(`keywordsAr[${i}]`, word)
    })


    axios.post(`${process.env.REACT_APP_API}/dashboard/blog/seo/${page}`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      setActive(false)
      toast('Seo Saved Successfully!', {theme: "colored", position:"top-right"})
    })
  }


  useEffect(() => {
    if (data) { 
      setSeoKeyWordsEn(data.keywordsEn||[])
      setSeoKeyWordsAr(data.keywordsAr||[])
      setSeoTitleEn(data.titleEn)
      setSeoTitleAr(data.titleAr)
      setSeoDescEn(data.descriptionEn)
      setSeoDescAr(data.descriptionAr)
      setSeoSlugEn(data.slugEn)
      setSeoSlugAr(data.slugAr)
      setSeoFaceTitleEn(data.facebookTitleEn)
      setSeoFaceTitleAr(data.facebookTitleAr)
      setSeoFaceDescEn(data.facebookDescriptionEn)
      setSeoFaceDescAr(data.facebookDescriptionAr)
      data.facebookImage&&setFacebookImage({preview: data.facebookImage})
      data.image&&setImage({preview: data.image})
    }
  }, [data])

  return (
    <div>
           {isOpen ? (
          <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
              setisOpen(!isOpen);
              }}
          />
          ) : null} 
      <Form className="border-0 pb-2">
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Focus Keypharse (english)</Label>
              <ReactTagInput 
                tags={seoKeyWordsEn}
                editable={true}
                placeholder="e.g " 
                onChange={tags => setSeoKeyWordsEn(tags)}
                required
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Focus Keypharse (arabic)</Label>
              <ReactTagInput 
                tags={seoKeyWordsAr}
                editable={true}
                placeholder="e.g " 
                onChange={tags => setSeoKeyWordsAr(tags)}
                required
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Meta Title (english)</Label>
              <Input
                id="brandnameen"
                name="brandnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Title"
                value={seoTitleEn}
                onChange={e => setSeoTitleEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Meta Title (arabic)</Label>
              <Input
                id="brandnamear"
                name="brandnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. عنوان"
                value={seoTitleAr}
                onChange={e => setSeoTitleAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Slug (english)</Label>
              <Input
                id="brandnameen"
                name="brandnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Slug"
                value={seoSlugEn}
                onChange={e => setSeoSlugEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Slug (arabic)</Label>
              <Input
                id="brandnamear"
                name="brandnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. وصف"
                value={seoSlugAr}
                onChange={e => setSeoSlugAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Meta Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={seoDescEn}
                onChange={e => setSeoDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Meta Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={seoDescAr}
                onChange={e => setSeoDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Meta Image</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleFacebookFiles(e, e.target.files, "image")}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {image ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={image.name}
                          src={image.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {image.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e =>
                              prevSelectImage(e, image.preview)
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                          type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e => setImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>

        <hr style={{ margin: "20px -20px 0px -20px" }} />

        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Facebook Image</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleFacebookFiles(e, e.target.files, "face")}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {facebookImage? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={facebookImage.name}
                          src={facebookImage.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {facebookImage.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e =>
                              prevSelectImage(e, facebookImage.preview)
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                          type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e =>  setFacebookImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Facebook Title (english)</Label>
              <Input
                id="brandnameen"
                name="brandnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Title"
                value={seoFaceTitleEn}
                onChange={e => setSeoFaceTitleEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Facebook Title (arabic)</Label>
              <Input
                id="brandnamear"
                name="brandnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. عنوان"
                value={seoFaceTitleAr}
                onChange={e => setSeoFaceTitleAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">
                Facebook Description (English)
              </Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={seoFaceDescEn}
                onChange={e => setSeoFaceDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Facebook Description (Arabic)</Label>
              <textarea
                className="form-control mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={seoFaceDescAr}
                onChange={e => setSeoFaceDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <hr style={{ margin: "20px -20px 10px -20px" }} />

        <Row>
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0 mt-3">
              <button type="button" onClick={e => submit()}>
                {active ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default SeoComponent
