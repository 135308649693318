import axios from "axios"
import AdminPagination from "pages/Utilities/AdminPagination"
import React, { useEffect, useState } from "react"
import Lightbox from "react-image-lightbox"
import { MetaTags } from "react-meta-tags"
import { Card, Col, Row, CardBody, Container } from "reactstrap"
import AddUserForm from "./component/AddUserForm"
import SweetAlert from "react-bootstrap-sweetalert"
import { useDispatch, useSelector } from "react-redux"
import { setTitle } from "../../store/actions"
import axiosConfig from "axiosConfig"
import {
  getNotifications,
  updateNotifications,
} from "store/notifications/actions"
import { toast } from "react-toastify"

function User() {
  const dispatch = useDispatch()
  const [users, setUsers] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [filter, setFilter] = useState("")
  const [open, setOpen] = useState(false)
  const [imgUrl, setImgUrl] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "image",
      text: "Image",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
    },
  ]

  const openImage = url => {
    setOpen(!open)
    setImgUrl(url)
  }

  const bindData = async () => {
    try {
      await axiosConfig
        .get(
          `${process.env.REACT_APP_API}/dashboard/configurations/users?page=${pageNo}&search=${filter}`,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
          }
        )
        .then(res => {
          const resData = res.data.data.users
          setUsers(
            resData.map(user => {
              return {
                ...user,
                name: user.name ? user.name : <span className="fs-4">-</span>,
                image: user.image ? (
                  <img
                    src={user.image}
                    onClick={e => openImage(e.target.src)}
                    className="usernametab rounded-circle"
                    style={{ width: "60px", height: "60px" }}
                    alt=""
                  />
                ) : (
                  <span className="fs-4">-</span>
                ),
                email: user.email ? (
                  user.email
                ) : (
                  <span className="fs-4">-</span>
                ),
                language: user.language ? (
                  user.language == "ar" ? (
                    "Arabic"
                  ) : (
                    "English"
                  )
                ) : (
                  <span className="fs-4">-</span>
                ),
                country: user?.Country?.nameEn || "-",
                Favourites: user.Favourites ? (
                  user.Favourites
                ) : (
                  <span className="fs-4">-</span>
                ),
                actions: (
                  <div className="d-flex gap-2">
                    {
                      <button
                        style={deleteBtn}
                        onClick={e => deleteItem(user.id)}
                      >
                        <i className="bx bx-trash fs-4" /> Delete
                      </button>
                    }
                  </div>
                ),
                created_at: user.created_at ? (
                  user.created_at
                ) : (
                  <span className="fs-4">-</span>
                ),
              }
            })
          )
          setTotalCount(res.data.data.pagination.total)
        })
    } catch (error) {}
  }

  useEffect(() => {
    dispatch(setTitle("Users"))
    bindData()
  }, [pageNo, filter])

  const onPageHandler = (type, { page }) => {
    setPageNo(page)
  }

  function deleteItem(id) {
    axiosConfig
      .post(
        `${process.env.REACT_APP_API}/dashboard/configurations/users/delete`,
        { id: id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        toast.success(res.data.message)
        bindData()
      })
  }

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/notifications/ReadNotifications`,
        { model: "user" },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        axios
          .get(
            `https://api.resourchub.com/api/dashboard/notifications/latest`,
            {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("authUser")).accessToken
                }`,
              },
            }
          )
          .then(res => {
            dispatch(
              getNotifications({
                notifications: res.data.data.notifications,
                notifyCount: res.data.data.notificationsCount,
                pendingCount: res.data.data.pendingNotificationsCount,
                userCount: res.data.data.userNotificationsCount,
              })
            )
          })
      })
  }, [])

  return (
    <div className="page-content">
      <MetaTags>
        <title>Users | Shoppy App</title>
      </MetaTags>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        ></SweetAlert>
      ) : null}
      {open ? (
        <Lightbox
          mainSrc={imgUrl}
          enableZoom={false}
          onCloseRequest={() => {
            setOpen(!open)
          }}
        ></Lightbox>
      ) : null}
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {
                  <AdminPagination
                    data={users}
                    page={pageNo}
                    setFilter={setFilter}
                    keyFiled="id"
                    sizePerPage={10}
                    totalSize={totalCount}
                    url=""
                    none={true}
                    onTableChange={onPageHandler}
                    buttonText="ss"
                    columns={columns}
                    nobtn
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "#000",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
export default User
