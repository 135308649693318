/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Card, CardBody, Collapse } from "reactstrap"
import classnames from "classnames"
import "./specification.scss"
import "react-image-lightbox/style.css"
// Form Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import HeaderContent from "./LearnUi/HeaderContent"
import CanAttend from "./LearnUi/CanAttend"
import WhatOffer from "./LearnUi/WhatOffer"
import HightlitsDesign from "./LearnUi/HightlitsDesign"
import Testimonials from "./LearnUi/Testimonials"
import UiDesign from "./LearnUi/UiDesign"
import UiUxDesign from "./LearnUi/UiUxDesign"
import UxDesign from "./LearnUi/UxDesign"
import StepsReach from "./LearnUi/StepsReach"
import Questions from "./LearnUi/Questions"

const LearnUiComp = () => {
  const [col, setCol] = useState([
    { id: 0, status: false },
    { id: 1, status: false },
    { id: 2, status: false },
    { id: 3, status: false },
    { id: 4, status: false },
    { id: 5, status: false },
    { id: 6, status: false },
    { id: 7, status: false },
    { id: 8, status: false },
    { id: 9, status: false },
    { id: 10, status: false },
  ])

  function ChangeColState(id) {
    setCol(col =>
      col.map(col => {
        if (col.id === id) {
          return { ...col, status: !col.status }
        }
        return { ...col, status: false }
      })
    )
  }

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Card>
          <CardBody>
            <div className="accordion" id="accordion">
              
              {/* Header Collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[1].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(1)}
                    style={{ cursor: "pointer" }}
                  >
                    Header Content
                  </button>
                </h2>
                <Collapse isOpen={col[1].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <HeaderContent />
                  </div>
                </Collapse>
              </div>

              {/* Can Attend Collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[2].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(2)}
                    style={{ cursor: "pointer" }}
                  >
                    Who Can Attend
                  </button>
                </h2>
                <Collapse isOpen={col[2].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <CanAttend />
                  </div>
                </Collapse>
              </div>

              {/* What Offer Collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[3].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(3)}
                    style={{ cursor: "pointer" }}
                  >
                    What Offer
                  </button>
                </h2>
                <Collapse isOpen={col[3].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <WhatOffer />
                  </div>
                </Collapse>
              </div>

              {/* Highlights Design Collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[4].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(4)}
                    style={{ cursor: "pointer" }}
                  >
                    Highlits Design
                  </button>
                </h2>
                <Collapse isOpen={col[4].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <HightlitsDesign />
                  </div>
                </Collapse>
              </div>

              {/* Testimonials Collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[5].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(5)}
                    style={{ cursor: "pointer" }}
                  >
                    Testimonials
                  </button>
                </h2>
                <Collapse isOpen={col[5].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Testimonials />
                  </div>
                </Collapse>
              </div>

              {/* UI Design Package */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[6].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(6)}
                    style={{ cursor: "pointer" }}
                  >
                    UI Design Package
                  </button>
                </h2>
                <Collapse isOpen={col[6].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <UiDesign />
                  </div>
                </Collapse>
              </div>

              {/* UX/UI Design Package */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[7].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(7)}
                    style={{ cursor: "pointer" }}
                  >
                    UX/UI Design Package
                  </button>
                </h2>
                <Collapse isOpen={col[7].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <UiUxDesign />
                  </div>
                </Collapse>
              </div>

              {/* UX Design Package */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[8].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(8)}
                    style={{ cursor: "pointer" }}
                  >
                    UX Design Package
                  </button>
                </h2>
                <Collapse isOpen={col[8].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <UxDesign />
                  </div>
                </Collapse>
              </div>

              {/* steps reach collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[9].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(9)}
                    style={{ cursor: "pointer" }}
                  >
                    Steps Reach Cards
                  </button>
                </h2>
                <Collapse isOpen={col[9].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <StepsReach />
                  </div>
                </Collapse>
              </div>

              {/* questions Collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[10].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(10)}
                    style={{ cursor: "pointer" }}
                  >
                    Questions Collapse
                  </button>
                </h2>
                <Collapse
                  isOpen={col[10].status}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <Questions />
                  </div>
                </Collapse>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default LearnUiComp

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
