import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Col, Collapse, Input, Label, Row } from 'reactstrap';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import "./styles.scss";
import axios from 'axios';
const TitlesForm = ({sortId, font, data, setData, index, deleteRow}) => {
    const [col, setCol] = useState(false);
 
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: sortId});
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

  return (
    <div className="accordion" id="accordion" ref={setNodeRef} style={{...style, position: "relative"}} >
        <div className="accordion-item"  >
            <h2 className="accordion-header" id="headingOne" >
               <button {...attributes} {...listeners} className="dragable_btn">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 12C11 13.1046 10.1046 14 9 14C7.89543 14 7 13.1046 7 12C7 10.8954 7.89543 10 9 10C10.1046 10 11 10.8954 11 12Z" fill="black"/>
                    <path d="M17 12C17 13.1046 16.1046 14 15 14C13.8954 14 13 13.1046 13 12C13 10.8954 13.8954 10 15 10C16.1046 10 17 10.8954 17 12Z" fill="black"/>
                    <path d="M11 6C11 7.10457 10.1046 8 9 8C7.89543 8 7 7.10457 7 6C7 4.89543 7.89543 4 9 4C10.1046 4 11 4.89543 11 6Z" fill="black"/>
                    <path d="M17 6C17 7.10457 16.1046 8 15 8C13.8954 8 13 7.10457 13 6C13 4.89543 13.8954 4 15 4C16.1046 4 17 4.89543 17 6Z" fill="black"/>
                    <path d="M11 18C11 19.1046 10.1046 20 9 20C7.89543 20 7 19.1046 7 18C7 16.8954 7.89543 16 9 16C10.1046 16 11 16.8954 11 18Z" fill="black"/>
                    <path d="M17 18C17 19.1046 16.1046 20 15 20C13.8954 20 13 19.1046 13 18C13 16.8954 13.8954 16 15 16C16.1046 16 17 16.8954 17 18Z" fill="black"/>
                </svg>
               </button>
            <button
            className={classNames(
                "accordion-button",
                "fw-medium",
                "fw-bold",
                "fs-5",
                "pl-5",
                { collapsed: !col }
            )}
            type="button"
            onClick={() => setCol(!col)}
            style={{ cursor: "pointer", paddingLeft: "40px",paddingRight: "40px", position: "relative" }}
            >
                {data.titleEn}
            </button>
                <button  className="delete_btn" onClick={() => (setData(data => data.filter((d, i) => i !== index)), deleteRow(data.RowID||null))}>
                    <svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.8333 4.33341C10.235 4.33341 9.75 4.81844 9.75 5.41675H16.25C16.25 4.81844 15.765 4.33341 15.1667 4.33341H10.8333ZM18.4167 7.58341H7.58333V18.4167C7.58333 20.2117 9.03841 21.6667 10.8333 21.6667H15.1667C16.9616 21.6667 18.4167 20.2117 18.4167 18.4167V7.58341ZM5.41667 7.58341V18.4167C5.41667 21.4083 7.84179 23.8334 10.8333 23.8334H15.1667C18.1582 23.8334 20.5833 21.4083 20.5833 18.4167V7.58341H21.6667C22.265 7.58341 22.75 7.09839 22.75 6.50008C22.75 5.90177 22.265 5.41675 21.6667 5.41675H18.4167C18.4167 3.62182 16.9616 2.16675 15.1667 2.16675H10.8333C9.03841 2.16675 7.58333 3.62182 7.58333 5.41675H4.33333C3.73502 5.41675 3.25 5.90177 3.25 6.50008C3.25 7.09839 3.73502 7.58341 4.33333 7.58341H5.41667Z" fill="#DE0000"/>
                    </svg>
                </button>
            </h2>
            <Collapse isOpen={col} className="accordion-collapse">
                <div className="accordion-body">
                    <Row className="">
                        <Col>
                            <div className="">
                                <Label htmlFor="productdesc">Title (English)</Label>
                                <Input
                                    name="nameEn"
                                    type="text"
                                    className="form-control input_cus rounded"
                                    placeholder="e.g.Name"
                                    value={data.titleEn}
                                    onChange={e => setData(data => data.map((d,i) => index == i? {...d, titleEn: e.target.value}:d))}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="">
                                <Label htmlFor="productdesc">Title (Arabic)</Label>
                                <Input
                                    name="nameEn"
                                    type="text"
                                    className="form-control  input_cus rounded"
                                    placeholder="e.g.Name"
                                    dir="rtl"
                                    value={data.titleAr}
                                    onChange={e => setData(data => data.map((d,i) => index == i? {...d, titleAr: e.target.value}:d))}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="">
                                <Label htmlFor="productdesc">Font Size</Label>
                                <select className="px-2 form-control  input_cus rounded"
                                    value={data.fontSizeID}
                                    onChange={e => setData(data => data.map((d,i) => index == i? {...d, fontSizeID: e.target.value}:d))}
                                >
                                    <option value="">Select</option>
                                    {font?.size?.map((font, i) => <option key={i} value={font.id}>{font.name} - {font.size}</option>)}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className="">
                                <Label htmlFor="productdesc">Font Type</Label>
                                <select className="px-2 form-control input_cus rounded"
                                    value={data.fontTypeID}
                                    onChange={e => setData(data => data.map((d,i) => index == i? {...d, fontTypeID: e.target.value}:d))}
                                >
                                    <option value="">Select</option>
                                    {font?.type?.map((font, i) => <option key={i} value={font.id}>{font.name}  - {font.size}</option>)}
                                </select>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </div>
    </div>

  )
}

export default TitlesForm