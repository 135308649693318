/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Table } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import ReactDragListView from "react-drag-listview/lib/index.js"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"
import AddCategoryFormSubResources from "./Forms/AddCategoryFormSubResources"

const CategoryForSubResources = props => {
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [data, setData] = useState([])
  const [category, setCategory] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [categories, setCategories] = useState([])
  const [filter, setFilter] = useState(0) 
  
  function clickHandle(category, set) {
    setActive(set)
    setCategory(category)
  }

  function deleteCategory(id) {
    axiosConfig
      .post(
        `/dashboard/uxProcess/configurations/category/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(products => products.filter(prod => prod.id != id))
        toast.success("Category Deleted Successfully!", {position: "top-right", theme: "colored"})
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
        const modifiedData = [...productData];
        const item = modifiedData.splice(fromIndex, 1)[0];
        modifiedData.splice(toIndex, 0, item);
        setProductData(modifiedData);
        console.log(filter)
        axios.post(`${process.env.REACT_APP_API}/dashboard/uxProcess/configurations/category/arrange`, {ids: modifiedData.map(cat =>cat.id),category : filter}, {
          headers: { 
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
          }
        })
        .then(res => {
          console.log(res.data);
        })
    },
    nodeSelector: 'tr',
    handleSelector: 'tr'
  };

  function getProductsData(index){
    setFilter(index)
    axios
      .get(
      `${process.env.REACT_APP_API}/dashboard/uxProcess/configurations/category?search=${index}`,
        {
          headers: {
            Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }
    )
    .then(res => {
      setData(res.data.data)
      setProductData(res.data.data)
          
    })
    .catch(err => {
    })
    
  }

  useEffect(() => {
    if(props.categoriesData) {
      setCategories(props.categoriesData)
      setFilter(props.categoriesData[0]?.id)
      getProductsData(props.categoriesData[0]?.id)
    }
    
  }, [props.categoriesData])

  function addCategorySuccsess() {
    setMsg("Category Added Succsessfully")
    setsuccess_msg(true)
  }

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          ></SweetAlert>
        ) : null}
        {active&&<AddCategoryFormSubResources
          category={category}
          active={active}
          setActive={setActive}
          clickHandle={clickHandle}
          getProductsData={getProductsData}
          filter = {filter}
          setFilter = {setFilter}
          addCategorySuccsess={addCategorySuccsess}
          setCategory={setCategory}
          categories={categories}
          setProductData={setProductData}
        />}

        <Row>
          <Col> 
            <Card>
              <CardBody>
                <select className="form-control input_cus" value={filter} onChange={(e) => getProductsData(e.target.value)}>
                  {categories.map((cat) => <option key={cat.id} value={cat.id}>{cat.nameEn}</option>)}
                </select>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-4">
                <Row>
                  <div className="save__btn top mb-2">
                    <button onClick={() => setActive(true)}>
                      <i className="bx bx-plus-circle fs-4" /> Add Sub Category
                    </button>
                  </div>
                </Row>
                <div className="table-responsive">
                  <ReactDragListView {...dragProps}>
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>id</th>
                          <th>Color</th>
                          <th>Name (English)</th>
                          <th>Name (Arabic)</th>
                          {/* <th>Viwers</th>
                          <th># of Visitors</th>
                          <th># of Sites</th> */}
                          <th>ِActions</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(productData || [])?.map((item, index) => (
                          <tr key={index}>
                            <td  style={tdStyle}>
                              {item.id}
                            </td>
                            {/* <td  style={tdStyle}>{item?.category?.nameEn}</td> */}
                            <td>
                              <span className='me-2' style={{backgroundColor: item.color, width: '25px',
                              height: '25px',
                              borderRadius: '50%',
                              border: "1px solid #ccc",
                              marginRight: "15px",
                              display: 'inline-block'}}>
                              </span>
                            </td>
                            <td  style={tdStyle}>{item.nameEn}</td>
                            <td  style={tdStyle}>{item.nameAr?item.nameAr:"-"}</td>
                            {/* <td  style={tdStyle}>{item.Viwers}</td>
                            <td  style={tdStyle}>{item.Visitors}</td>
                            <td  style={tdStyle}>{item.ofSites}</td> */}
                            <td  style={tdStyle}>{
                              (
                                <div className="d-flex">
                                  <button
                                    style={editBtn}
                                    onClick={() => clickHandle(item, true)}
                                  >
                                    <i
                                      className="bx bx-edit fs-4 pr-1"
                                      style={{ marginRight: "10px" }}
                                    ></i>{" "}
                                    Edit
                                  </button>
                                  {props.roles&& <button
                                    style={deleteBtn}
                                    onClick={e => deleteCategory(item.id)}
                                  >
                                    <i
                                      className="bx bx-trash fs-4"
                                      style={{ marginRight: "10px" }}
                                    ></i>{" "}
                                    Delete
                                  </button>}
                                </div>
                              )
                            }</td>
                            <td  style={tdStyle}>{item.created_at}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </ReactDragListView>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default CategoryForSubResources

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const imageStyle = {
  width: "60px",
  height: "60px",
  display: "block",
  objectFit: "contain",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const tdStyle = { 
  whiteSpace: "nowrap",
  verticalAlign: "middle"
}