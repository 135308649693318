/* eslint-disable react/prop-types */

import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator"
import { Col, Row } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"

const MySearch = ({setFilter}) => {
  let input;
  const handleClick = (value) => {
    setFilter(value)
  };
  return (
    <div>
      <input
        placeholder="Search"
        style={{ borderRadius: 'none', padding:'10px 10px 10px 35px',border:'1px solid #CCC', textTransform:'none' }}
        ref={ n => input = n }
        type="text"
        onChange={e=>handleClick(e.target.value)}
      />
    </div>
  );
};
function AdminPagination(props) {
  
 const {history, data, keyFiled, page, 
  sizePerPage,buttonText, onTableChange, totalSize,
   columns, setFilter,setActive, search,
   none,url }=props
  return (
    <div>
      <PaginationProvider
        pagination={
          paginationFactory({
            custom: true,
            page,
            sizePerPage,
            totalSize
          })
        }
      >
        {
          ({ 
            paginationProps,
            paginationTableProps
          }) => (
            <ToolkitProvider
            keyField={keyFiled}
            columns={columns}
            data={data}
          >
            {props=>(
              <>
              <Row className="mb-2">
                  <Col sm="6">
                    <div className={search==="none"? "d-none":"search-box me-2 mb-2 d-inline-block"}>
                      <div className="position-relative ">
                        <MySearch setFilter={setFilter} />
                        <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                      </div>
                    </div>
                  </Col>
                  <Col>
                  <div className="save__btn top">
                    <button onClick={() =>  history.push(`/uiux/article-steps/article-form`)}>
                      <i className="bx bx-plus-circle fs-4"></i>{" "}
                      Add Article
                    </button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <div className={"table-responsive"}>
                    <BootstrapTable
                      remote
                      {...props.baseProps}
                      onTableChange={onTableChange}
                      classes={
                        "table align-middle table-nowrap"
                      }
                      headerWrapperClasses={"thead-light"}
                      {...paginationTableProps}
                    />
                    {data.length === 0?<div className="emptyStateTable" style={{
                      width: '100%',
                      textAlign: 'left',
                      fontSize: '16px',
                      padding: '10px',
                      paddingTop: '0', 
                      paddingBottom: "20px"
                    }}>no data available in table</div>:null}

                    {data.length !== 0 ? <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone
                            {...paginationProps}
                          />
                        </div>
                      </Col>
                    </Row> : null}
                  </div>
                </Col>
              </Row>
              </>
              
            )}
            
            </ToolkitProvider>
          )
        }
      </PaginationProvider>
    </div>
  )
}
AdminPagination.propTypes = {
    products: PropTypes.array,
    history: PropTypes.object,
    onGetProducts: PropTypes.func,
  }
export default withRouter(AdminPagination)
