import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect, useDispatch } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

//import firebase
import { getToken, Fire } from './Firebase'

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"


import fakeBackend from "./helpers/AuthType/fakeBackend"
import {toast} from "react-toastify"
import axios from 'axios'
import { getMainData } from 'store/actions'
import { MetaTags } from 'react-meta-tags'

// Activating fake backend
// fakeBackend()

const App = props => {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/getdDashboardSetting`).then(res => {
      dispatch(getMainData(res.data.data))
      setData(res.data.data) 
      document.documentElement.style.setProperty('--main-color', res.data.data.buttonColor);
      document.documentElement.style.setProperty('--side-color', res.data.data.sideBarColor);
      document.documentElement.style.setProperty('--text-color', res.data.data.textSideBarColor);
    })
  }, [])

  return (
    <React.Fragment>
      <Fire />
      <MetaTags>
        <link rel="icon" href={data?.favIcon} />
      </MetaTags>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
