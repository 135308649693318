import React, { Fragment, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Select from "react-select"


const HightlitsDesign = () => {
  const [active, setActive] = useState(false)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [subTitleEn, setSubTitleEn] = useState("")
  const [subTitleAr, setSubTitleAr] = useState("")
  const [highLights, setHighLights] = useState([])
  


  const optionGroup = [
    {
      label: "test1",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "test2",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]


  function submit(e) {
    e.preventDefault()
    const data = {
      titleEn,
      titleAr,
      subTitleEn,
      subTitleAr,
      highLights
    }
 
   
    axios.post(`${process.env.REACT_APP_API}/admin/`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).authToken
        }`,
      },
    })
  }

  return (
    <div>
      <Form className="border-0 p-0">
        <Row>
          <Col className="">
            <Form className="border-0">
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title Of Hightlight (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Title Attend"
                      value={titleEn}
                      onChange={e => setTitleEn(e.target.value)}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title Of What Offer (arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={titleAr}
                      onChange={e => setTitleAr(e.target.value)}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Sub Title Of Hightlight (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title Attend"
                      value={subTitleEn}
                      onChange={e => setSubTitleEn(e.target.value)}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Sub Title Of What Offer (arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={subTitleAr}
                      onChange={e => setSubTitleAr(e.target.value)}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                    <Col>
                      <div>
                        <Label htmlFor="productdesc">
                          Multi Select HighLight
                        </Label>
                        <Select
                          value={highLights}
                          isMulti={true}
                          onChange={(e) => {
                            setHighLights(e)
                          }}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Form>
      <hr style={{ margin: "25px -20px 20px -20px" }} />
      <Row>
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HightlitsDesign

const cardStyle = {
  borderTop: "2px solid #dedede",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
