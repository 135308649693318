import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import axios from "axios"
import { toast } from "react-toastify"

const HeaderContent = ({data}) => {
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [subTitleEn, setSubTitleEn] = useState("")
  const [subTitleAr, setSubTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [createByEn, setCreateByEn] = useState("")
  const [createByAr, setCreateByAr] = useState("")
  const [createInEn, setCreateInEn] = useState("")
  const [createInAr, setCreateInAr] = useState("")
  const [speakerEn, setSpeakerEn] = useState("")
  const [speakerAr, setSpeakerAr] = useState("")
  const [coverImage, setCoverImage] = useState(null)
  const [createdByImage, setCreatedByImage] = useState(null)
  const [createdInImage, setCreatedInImage] = useState(null)
  const [speakerImage, setSpeakerImage] = useState(null)

  function handleCoverImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setCoverImage(Object.values(files)[0])
    e.target.value = ""
  }
  function handleCreateByImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setCreatedByImage(Object.values(files)[0])
    e.target.value = ""
  }
  function handleCreateInImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setCreatedInImage(Object.values(files)[0])
    e.target.value = ""
  }
  function handleSpeakerImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSpeakerImage(Object.values(files)[0])
    e.target.value = ""
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }

  function submit(e) {
    e.preventDefault()
    setActive(true)
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
      subTitleEn,
      subTitleAr,
      createByEn,
      createdByAr: createByAr,
      createdInEn:createInEn,
      createdInAr: createInAr,
      speakerEn,
      speakerAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append("image", coverImage)
    formData.append("iconOfCreated", createdByImage)
    formData.append("iconInCreated", createdInImage)
    formData.append("iconOfSpeaker", speakerImage)
    axios.post(`${process.env.REACT_APP_API}/dashboard/learnUi/headerContent`, formData, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
      },
    }).then(res => {
      toast.success("Data Saved Successfully", {position: "top-right"})
      setActive(false)
    })
  }

  useEffect(() => {
    if(data) {
      setTitleEn(data.titleEn)
      setTitleAr(data.titleAr)
      setSubTitleEn(data.subTitleEn)
      setSubTitleAr(data.subTitleAr)
      setDescEn(data.descriptionEn)
      setDescAr(data.descriptionAr)
      setCreateByEn(data.createByEn)
      setCreateByAr(data.createdByAr)
      setCreateInEn(data.createdInEn)
      setCreateInAr(data.createdInAr)
      setSpeakerEn(data.speakerEn)
      setSpeakerAr(data.speakerAr)
      setCoverImage(data?.image ? {preview: data.image} : null )
      setSpeakerImage( data?.iconOfSpeaker ?  {preview: data.iconOfSpeaker} : null)
      setCreatedByImage( data?.iconOfCreated ? {preview: data.iconOfCreated} : null)
      setCreatedInImage( data?.iconInCreated ? {preview: data.iconInCreated} : null)
    }
    
  }, [data])


  return (
    <div>
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 p-0">
        <Row>
          <Col className="">
            <Form className="border-0 pb-2">
              <Row>
                <Col xs="12">
                  <div className="">
                    <Label htmlFor="productdesc">Background Image</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      onChange={e => handleCoverImage(e, e.target.files)}
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
                <Col xs="12">
                  <div className="dropzone-previews" id="file-previews">
                    {coverImage ? (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={coverImage.name}
                                src={coverImage.preview}
                                style={{ objectFit: "cover" }}
                              />
                            </Col>
                            <Col>
                              <Link to="#" className="text-muted ">
                                {coverImage.name}
                              </Link>
                            </Col>
                            <Col>
                              <div className="image__options">
                                <button
                                  className="p-0"
                                  onClick={e =>
                                    prevSelectImage(e, coverImage.preview)
                                  }
                                >
                                  <i className="far fa-eye  fs-5 p-0" />
                                </button>
                                <button
                                  style={{ color: "#ff3838" }}
                                  onClick={e => setCoverImage(null)}
                                >
                                  <i className="bx bx-trash fs-4" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Title Header (english)</Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Title Header"
                      value={titleEn}
                      onChange={e => setTitleEn(e.target.value)}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Title Header (arabic)</Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={titleAr}
                      onChange={e => setTitleAr(e.target.value)}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Sub Title (english)</Label>
                    <Input
                      id="brandnameen"
                      name="brandnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title"
                      value={subTitleEn}
                      onChange={e => setSubTitleEn(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Sub Title (arabic)</Label>
                    <Input
                      id="brandnamear"
                      name="brandnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={subTitleAr}
                      onChange={e => setSubTitleAr(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Header (English)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="5"
                      placeholder="Enter your description here"
                      value={descEn}
                      onChange={e => setDescEn(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Header (Arabic)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="5"
                      placeholder="اكتب وصف هنا"
                      value={descAr}
                      onChange={e => setDescAr(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "25px -20px 25px -20px" }} />
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Created by (english)</Label>
                    <Input
                      id="brandnameen"
                      name="brandnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. description"
                      value={createByEn}
                      onChange={e => setCreateByEn(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Created by (arabic)</Label>
                    <Input
                      id="brandnamear"
                      name="brandnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={createByAr}
                      onChange={e => setCreateByAr(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="mt-3">
                    <Label htmlFor="productdesc">Icon Of Created</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      onChange={e => handleCreateByImage(e, e.target.files)}
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
                <Col xs="12">
                  <div className="dropzone-previews mb-3" id="file-previews">
                    {createdByImage ? (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={createdByImage.name}
                                src={createdByImage.preview}
                                style={{ objectFit: "cover" }}
                              />
                            </Col>
                            <Col>
                              <Link to="#" className="text-muted ">
                                {createdByImage.name}
                              </Link>
                            </Col>
                            <Col>
                              <div className="image__options">
                                <button
                                  className="p-0"
                                  onClick={e =>
                                    prevSelectImage(e, createdByImage.preview)
                                  }
                                >
                                  <i className="far fa-eye  fs-5 p-0" />
                                </button>
                                <button
                                  style={{ color: "#ff3838" }}
                                  onClick={e => setCreatedByImage(null)}
                                >
                                  <i className="bx bx-trash fs-4" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Create in (english)</Label>
                    <Input
                      id="brandnameen"
                      name="brandnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. description"
                      value={createInEn}
                      onChange={e => setCreateInEn(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Create in (arabic)</Label>
                    <Input
                      id="brandnamear"
                      name="brandnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={createInAr}
                      onChange={e => setCreateInAr(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="mt-3">
                    <Label htmlFor="productdesc">Icon Of Time</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      onChange={e => handleCreateInImage(e, e.target.files)}
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
                <Col xs="12">
                  <div className="dropzone-previews mb-3" id="file-previews">
                    {createdInImage ? (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={createdInImage.name}
                                src={createdInImage.preview}
                                style={{ objectFit: "cover" }}
                              />
                            </Col>
                            <Col>
                              <Link to="#" className="text-muted ">
                                {createdInImage.name}
                              </Link>
                            </Col>
                            <Col>
                              <div className="image__options">
                                <button
                                  className="p-0"
                                  onClick={e =>
                                    prevSelectImage(e, createdInImage.preview)
                                  }
                                >
                                  <i className="far fa-eye  fs-5 p-0" />
                                </button>
                                <button
                                  style={{ color: "#ff3838" }}
                                  onClick={e => setCreatedInImage(null)}
                                >
                                  <i className="bx bx-trash fs-4" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Speaker (english)</Label>
                    <Input
                      id="brandnameen"
                      name="brandnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. description"
                      value={speakerEn}
                      onChange={e => setSpeakerEn(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Speaker (arabic)</Label>
                    <Input
                      id="brandnamear"
                      name="brandnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={speakerAr}
                      onChange={e => setSpeakerAr(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="mt-3">
                    <Label htmlFor="productdesc">Icon Of Speaker</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      onChange={e => handleSpeakerImage(e, e.target.files)}
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
                <Col xs="12">
                  <div className="dropzone-previews mb-3" id="file-previews">
                    {speakerImage ? (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={speakerImage.name}
                                src={speakerImage.preview}
                                style={{ objectFit: "cover" }}
                              />
                            </Col>
                            <Col>
                              <Link to="#" className="text-muted ">
                                {speakerImage.name}
                              </Link>
                            </Col>
                            <Col>
                              <div className="image__options">
                                <button
                                  className="p-0"
                                  onClick={e =>
                                    prevSelectImage(e, speakerImage.preview)
                                  }
                                >
                                  <i className="far fa-eye  fs-5 p-0" />
                                </button>
                                <button
                                  style={{ color: "#ff3838" }}
                                  onClick={e => setSpeakerImage(null)}
                                >
                                  <i className="bx bx-trash fs-4" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Form>
      <hr style={{ margin: "5px -20px 20px -20px" }} />

      <Row className="mt-3">
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button onClick={e => submit(e)}>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HeaderContent
