/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row,Container } from "reactstrap";

const Cards = (props) => {
    const [data, setData] = useState({
        totCategory : 0, 
        totSubCategory: 0, 
        totArticles: 0, 
        totViewers: 0, 
        totLinks: 0, 
        totVideo: 0,
        totDownloadFile: 0,
        totCountries: 0, 
        
    })

    useEffect(() => {
        if(props.data) {
            let {
                    totCategory, 
                    totSubCategory, 
                    totArticles, 
                    totViewers, 
                    totLinks, 
                    totVideo,
                    totDownloadFile,
                    totCountries, 
                    
                    
                } = props.data
                setData( {
                    totCategory, 
                    totSubCategory, 
                    totArticles, 
                    totViewers, 
                    totLinks, 
                    totVideo,
                    totDownloadFile,
                    totCountries, 
                    
                })
        }
    }, [props.data])
    return (
        <Row>
            
            <Col xl={3} md={4} sm={6} xs={12}>
            <Card className="mini-stats-wid">
            <CardBody>
                <div className="d-flex flex-wrap">
                <div className="me-3">
                    <p className="text-muted mb-2">Total Of Category</p>
                    <h5 className="mb-0">{data.totCategory}</h5>
                </div>
                <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <svg width="30" height="30" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 27.5923V31.5923H14L11 34.5923L6 36.9923C3.3 37.6923 1.1 39.5923 1 41.5923V48.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M31 10.5923V12.5923L33 13.5923V17.4923C33 18.1923 32.7 18.7923 32.1 19.1923L31 20.5923C31 20.5923 31 22.1923 31 22.5923C31 26.4923 25.9 28.1923 25 28.5923C24.1 28.9923 22.6 29.5923 21 29.5923C19.4 29.5923 17.9 28.9923 17 28.5923C16.1 28.1923 11 26.4923 11 22.5923C11 22.0923 11 20.5923 11 20.5923L9.9 19.1923C9.3 18.7923 9 18.1923 9 17.4923V13.5923L11 12.5923V10.5923C11 5.59229 15 1.59229 20 1.59229H22C27 1.59229 31 5.59229 31 10.5923Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M15 31.5923L21 36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M21 36.5923L16 40.5923L11 34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M27 27.5923V31.5923H28L31 34.5923L36 36.9923C38.7 37.6923 40.9 39.6923 41 41.5923V48.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M27 31.5923L21 36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M21 36.5923L26 40.5923L31 34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M24 38.5923L23 43.5923H19L18 38.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M24 47.5923L23 43.5923H19L18 47.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        </svg>
                    </div>
                </div>
                </div>
            </CardBody>
            </Card>
        </Col>
        <Col xl={3} md={4} sm={6} xs={12}>
            <Card className="mini-stats-wid">
            <CardBody>
                <div className="d-flex flex-wrap">
                <div className="me-3">
                    <p className="text-muted mb-2">Total Of Sub-Category</p>
                    <h5 className="mb-0">{data.totSubCategory}</h5>
                </div>
                <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <svg width="30" height="30" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 27.5923V31.5923H14L11 34.5923L6 36.9923C3.3 37.6923 1.1 39.5923 1 41.5923V48.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M31 10.5923V12.5923L33 13.5923V17.4923C33 18.1923 32.7 18.7923 32.1 19.1923L31 20.5923C31 20.5923 31 22.1923 31 22.5923C31 26.4923 25.9 28.1923 25 28.5923C24.1 28.9923 22.6 29.5923 21 29.5923C19.4 29.5923 17.9 28.9923 17 28.5923C16.1 28.1923 11 26.4923 11 22.5923C11 22.0923 11 20.5923 11 20.5923L9.9 19.1923C9.3 18.7923 9 18.1923 9 17.4923V13.5923L11 12.5923V10.5923C11 5.59229 15 1.59229 20 1.59229H22C27 1.59229 31 5.59229 31 10.5923Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M15 31.5923L21 36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M21 36.5923L16 40.5923L11 34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M27 27.5923V31.5923H28L31 34.5923L36 36.9923C38.7 37.6923 40.9 39.6923 41 41.5923V48.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M27 31.5923L21 36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M21 36.5923L26 40.5923L31 34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M24 38.5923L23 43.5923H19L18 38.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M24 47.5923L23 43.5923H19L18 47.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        </svg>
                    </div>
                </div>
                </div>
            </CardBody>
            </Card>
        </Col>
        <Col xl={3} md={4} sm={6} xs={12}>
            <Card className="mini-stats-wid">
            <CardBody>
                <div className="d-flex flex-wrap">
                <div className="me-3">
                    <p className="text-muted mb-2">Total Of Articles</p>
                    <h5 className="mb-0">{data.totArticles}</h5>
                </div>
                <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <svg width="30" height="30" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 27.5923V31.5923H14L11 34.5923L6 36.9923C3.3 37.6923 1.1 39.5923 1 41.5923V48.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M31 10.5923V12.5923L33 13.5923V17.4923C33 18.1923 32.7 18.7923 32.1 19.1923L31 20.5923C31 20.5923 31 22.1923 31 22.5923C31 26.4923 25.9 28.1923 25 28.5923C24.1 28.9923 22.6 29.5923 21 29.5923C19.4 29.5923 17.9 28.9923 17 28.5923C16.1 28.1923 11 26.4923 11 22.5923C11 22.0923 11 20.5923 11 20.5923L9.9 19.1923C9.3 18.7923 9 18.1923 9 17.4923V13.5923L11 12.5923V10.5923C11 5.59229 15 1.59229 20 1.59229H22C27 1.59229 31 5.59229 31 10.5923Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M15 31.5923L21 36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M21 36.5923L16 40.5923L11 34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M27 27.5923V31.5923H28L31 34.5923L36 36.9923C38.7 37.6923 40.9 39.6923 41 41.5923V48.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M27 31.5923L21 36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M21 36.5923L26 40.5923L31 34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M24 38.5923L23 43.5923H19L18 38.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M24 47.5923L23 43.5923H19L18 47.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        </svg>
                    </div>
                </div>
                </div>
            </CardBody>
            </Card>
        </Col>
        
        <Col xl={3} md={4} sm={6} xs={12}>
            <Card className="mini-stats-wid">
            <CardBody>
                <div className="d-flex flex-wrap">
                <div className="me-3">
                    <p className="text-muted mb-2">Total Of Viwers</p>
                    <h5 className="mb-0">{data.totViewers}</h5>
                </div>
                <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <svg width="30" height="30" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 27.5923V31.5923H14L11 34.5923L6 36.9923C3.3 37.6923 1.1 39.5923 1 41.5923V48.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M31 10.5923V12.5923L33 13.5923V17.4923C33 18.1923 32.7 18.7923 32.1 19.1923L31 20.5923C31 20.5923 31 22.1923 31 22.5923C31 26.4923 25.9 28.1923 25 28.5923C24.1 28.9923 22.6 29.5923 21 29.5923C19.4 29.5923 17.9 28.9923 17 28.5923C16.1 28.1923 11 26.4923 11 22.5923C11 22.0923 11 20.5923 11 20.5923L9.9 19.1923C9.3 18.7923 9 18.1923 9 17.4923V13.5923L11 12.5923V10.5923C11 5.59229 15 1.59229 20 1.59229H22C27 1.59229 31 5.59229 31 10.5923Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M15 31.5923L21 36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M21 36.5923L16 40.5923L11 34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M27 27.5923V31.5923H28L31 34.5923L36 36.9923C38.7 37.6923 40.9 39.6923 41 41.5923V48.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M27 31.5923L21 36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M21 36.5923L26 40.5923L31 34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M24 38.5923L23 43.5923H19L18 38.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M24 47.5923L23 43.5923H19L18 47.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        </svg>
                    </div>
                </div>
                </div>
            </CardBody>
            </Card>
        </Col>
        <Col xl={3} md={4} sm={6} xs={12}>
            <Card className="blog-stats-wid">
            <CardBody>
                <div className="d-flex flex-wrap">
                <div className="me-3">
                    <p className="text-muted mb-2">Total Of Links</p>
                    <h5 className="mb-0">{data.totLinks}</h5>
                </div>

                <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <svg width="30" height="30" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 21.5923C22.5 21.5923 26 18.2923 26 13.5923V9.59229C26 4.89229 22.5 1.59229 18 1.59229C13.5 1.59229 10 4.89229 10 9.59229V13.5923C10 18.2923 13.5 21.5923 18 21.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M24 25.3923C21.8 24.8923 19.6 24.5923 18 24.5923C12.4 24.5923 1 27.4923 1 33.5923V41.5923" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M36 43.5923C42.0751 43.5923 47 38.6674 47 32.5923C47 26.5172 42.0751 21.5923 36 21.5923C29.9249 21.5923 25 26.5172 25 32.5923C25 38.6674 29.9249 43.5923 36 43.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M36 24.5923V32.5923L40 35.5923" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                        </svg>

                    </div>
                </div>
                </div>
            </CardBody>
            </Card>
        </Col>
        <Col xl={3} md={4} sm={6} xs={12}>
            <Card className="blog-stats-wid">
            <CardBody>
                <div className="d-flex flex-wrap">
                <div className="me-3">
                    <p className="text-muted mb-2">Total Of Videos</p>
                    <h5 className="mb-0">{data.totVideo}</h5>
                </div>

                <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <svg width="30" height="30" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 21.5923C22.5 21.5923 26 18.2923 26 13.5923V9.59229C26 4.89229 22.5 1.59229 18 1.59229C13.5 1.59229 10 4.89229 10 9.59229V13.5923C10 18.2923 13.5 21.5923 18 21.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M24 25.3923C21.8 24.8923 19.6 24.5923 18 24.5923C12.4 24.5923 1 27.4923 1 33.5923V41.5923" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M36 43.5923C42.0751 43.5923 47 38.6674 47 32.5923C47 26.5172 42.0751 21.5923 36 21.5923C29.9249 21.5923 25 26.5172 25 32.5923C25 38.6674 29.9249 43.5923 36 43.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M36 24.5923V32.5923L40 35.5923" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                        </svg>

                    </div>
                </div>
                </div>
            </CardBody>
            </Card>
        </Col>
        <Col xl={3} md={4} sm={6} xs={12}>
            <Card className="blog-stats-wid">
            <CardBody>
                <div className="d-flex flex-wrap">
                <div className="me-3">
                    <p className="text-muted mb-2">Total Of Download Files</p>
                    <h5 className="mb-0">{data.totDownloadFile}</h5>
                </div>

                <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <svg width="30" height="30" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 21.5923C22.5 21.5923 26 18.2923 26 13.5923V9.59229C26 4.89229 22.5 1.59229 18 1.59229C13.5 1.59229 10 4.89229 10 9.59229V13.5923C10 18.2923 13.5 21.5923 18 21.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M24 25.3923C21.8 24.8923 19.6 24.5923 18 24.5923C12.4 24.5923 1 27.4923 1 33.5923V41.5923" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M36 43.5923C42.0751 43.5923 47 38.6674 47 32.5923C47 26.5172 42.0751 21.5923 36 21.5923C29.9249 21.5923 25 26.5172 25 32.5923C25 38.6674 29.9249 43.5923 36 43.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M40.2422 28.3496L31.7569 36.8349" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M40.2431 36.8349L31.7578 28.3496" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                        </svg>
                    </div>
                </div>
                </div>
            </CardBody>
            </Card>
        </Col>
        <Col xl={3} md={4} sm={6} xs={12}>
            <Card className="blog-stats-wid">
            <CardBody>
                <div className="d-flex flex-wrap">
                <div className="me-3">
                    <p className="text-muted mb-2">Total Of Countries</p>
                    <h5 className="mb-0">{data.totCountries}</h5>
                </div>

                <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <svg width="30" height="30" viewBox="0 0 39 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 40.5923V37.5923C4 36.4923 4.9 35.5923 6 35.5923H10" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M19 35.5923H23C24.1 35.5923 25 36.4923 25 37.5923V40.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M12 40.5923H3C1.9 40.5923 1 41.4923 1 42.5923V46.5923H28V42.5923C28 41.4923 27.1 40.5923 26 40.5923H21" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M19 16.5923V21.5923L25 27.5923L22 30.5923V35.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M21 6.59229L28 13.5923V16.5923L25 19.5923L22 16.5923H17C15.1 16.5923 13 15.4923 13 13.5923V12.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M7 35.5923V25.5923L4 22.5923V13.5923C4 5.69229 10.8 1.59229 17 1.59229C19.6 1.59229 23.3 2.99229 25 4.59229L22 7.59229" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M25 23.5923C26 21.7923 28 20.5923 30.2 20.5923C33.5 20.5923 36.2 23.2923 36.2 26.5923C36.2 28.6923 35.1 30.4923 33.5 31.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M28 31.5923H36C36.6 31.5923 37 31.9923 37 32.5923V34.5923C37 35.1923 36.6 35.5923 36 35.5923H31" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M30 46.5923H38V44.5923C38 43.4923 37.1 42.5923 36 42.5923H32" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M35 35.5923V42.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                    </svg>
                    </div>
                </div>
                </div>
            </CardBody>
            </Card>
        </Col>
       
                
       
        </Row>
    )
}

export default Cards