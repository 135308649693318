/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Table } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import ReactDragListView from "react-drag-listview/lib/index.js"
import { toast } from "react-toastify"
import AddAuthorFormResources from "./Forms/AddAuthorFormResources"

const AuthorResources = props => {
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [category, setCategory] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  function deleteCategory(id) {
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/blog/configurations/author/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(products => products.filter(prod => prod.id != id))
        toast.success("Category Deleted Success", { position: "top-right" })
        props.getMain()
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...productData]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setProductData(modifiedData)
      axios
        .post(
          `${process.env.REACT_APP_API}/dashboard/blog/configurations/author/arrange`,
          { ids: modifiedData.map(cat => cat.id) },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
          }
        )
        .then(res => {
          console.log(res.data)
        })
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  function getResourceData() {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/blog/configurations/author`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(
          res.data.data?.map(category => {
            return {
              ...category,
              actions: (
                <div className="d-flex">
                  <button
                    style={editBtn}
                    onClick={() => {
                      setActive(true)
                      setCategory(category)
                    }}
                  >
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Edit
                  </button>
                  <button
                    style={deleteBtn}
                    onClick={e => deleteCategory(category.id)}
                  >
                    <i
                      className="bx bx-trash fs-4"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Delete
                  </button>
                </div>
              ),
            }
          })
        )
      })
      .catch(err => {
        // err.response.data
      })
  }

  useEffect(() => {
    getResourceData()
  }, [])

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          ></SweetAlert>
        ) : null}
        {active && (
          <AddAuthorFormResources
            category={category}
            active={active}
            setActive={setActive}
            setProductData={setProductData}
            setCategory={setCategory}
            deleteCategory={deleteCategory}
            getResourceData={getResourceData}
            getMain={props.getMain}
          />
        )}
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-4">
                <Row>
                  <div className="save__btn top mb-2">
                    <button
                      onClick={() => {
                        setActive(true)
                        setCategory(null)
                      }}
                    >
                      <i className="bx bx-plus-circle fs-4" /> Add Author
                    </button>
                  </div>
                </Row>
                <div className="table-responsive">
                  <ReactDragListView {...dragProps}>
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>id</th>
                          <th>Image</th>
                          <th>Name (English)</th>
                          <th>Name (Arabic)</th>
                          <th>Position (English)</th>
                          <th>Position (Arabic)</th>
                          <th># of Blogs</th>
                          <th>ِActions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(productData || [])?.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>{item.id}</td>
                            <td>
                              <img
                                src={item.image}
                                alt="author name"
                                style={{
                                  borderRadius: "50px",
                                  width: "60px",
                                  aspectRatio: "1/1",
                                }}
                              />
                            </td>
                            <td style={tdStyle}>{item.nameEn}</td>
                            <td style={tdStyle}>
                              {item.nameAr ? item.nameAr : "-"}
                            </td>
                            <td style={tdStyle}>{item.positionEn}</td>
                            <td style={tdStyle}>
                              {item.positionAr ? item.positionAr : "-"}
                            </td>

                            <td style={tdStyle}>{item.Blogs}</td>
                            <td style={tdStyle}>{item.actions}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </ReactDragListView>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default AuthorResources

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
const imageStyle = {
  width: "60px",
  height: "60px",
  display: "block",
  objectFit: "contain",
}
const tdStyle = {
  whiteSpace: "nowrap",
  verticalAlign: "middle",
}
