import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { updateNotifications } from "store/actions"
const NotificationDropdown = props => {
  const dispatch = useDispatch()
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const notify = useSelector(state => state.Notifications)


  function open() {
    setMenu(!menu)
    axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, {key: "7"},{
      headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
      }
    }).then(res => {
        dispatch(updateNotifications({unreadNotificationCount: 0}))
    })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => open()}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <div style={{display:"flex", alignItems: "center", gap: "5px", color: "#000000"}}>
              <i className="bx bx-bell bx-tada" style={{color: "#000000"}}/>
              <span className="badge bg-danger rounded-pill">{notify.notifyCount!==0 && notify.notifyCount<=9?0:null}{notify.notifyCount===0?null:notify.notifyCount}</span>
              <span className='fs-5'>Notifications</span>
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notify.notifications? (notify.notifications ||[]).map((n, i) => {
              return(
                  <Link key={i} to={n.type==="user"? "/users":
                  n.type==="toy"?"/toys":
                  n.type==="suggestion"?"/suggestions":
                  n.type==="feedback"?"/feedback":
                  n.type==="review"?"/reviews":
                  n.type==="report"?"/reports":
                  "/"} className="text-reset notification-item" >
                    <div className="media">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          {n.type==="user"? <i className="fas fa-users fs-5"></i>:
                            n.type==="toy"?<i className="mdi mdi-tractor fs-3"></i>:
                            n.type==="suggestion"?<i className='bx bx-bulb fs-3'></i>:
                            n.type==="feedback"?<i className='bx bx-message-square-dots'></i>:
                            n.type==="review"?<i className='bx bx-message-dots' ></i>:
                            n.type==="report "?<i className='bx bxs-report' ></i>:
                            <i className="bx bx-bell"></i> 
                          }
                        </span>
                      </div>
                      <div className="media-body">

                        <h6 className="mt-0 mb-1">
                            {n.title}
                        </h6>

                        <div className="font-size-12 text-muted">
                          
                          <p className="mb-1">
                            {n.body}
                          </p>

                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" />{" "}
                            {n.date}{" "}
                          </p>

                        </div>

                      </div>
                    </div>
                  </Link>
              )}):null}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notifications"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}