/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import { toast } from "react-toastify"; 
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
const DashTable = (props) => {
    const [active, setActive] = useState(false)
    const[productData, setProductData] = useState([])
    const[editCop, setEditCop] = useState(null)
    const [isOpen, setisOpen] = useState(false);
    const [prev, setPrev] = useState('');
    const [columns, setColumns] = useState([
        {
            dataField: 'id',
            text: 'Id',
            sort: true,
          }
    ])
    

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 50,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    }

    function setOpen(img) {
        setisOpen(true)
        setPrev(img)
    }
    const { SearchBar } = Search;

    useEffect(() => {
        setColumns(props.columns)
        setProductData(props.data)
        
    },[props])
    
    return (
        <Card >
            <CardBody className="pb-0">
                
                <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField='id'
                columns={columns}
                data={productData}
                >
                {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                    keyField='id'
                    columns={columns}
                    data={productData}
                    search
                    >
                    {toolkitProps => (
                        <React.Fragment>
                        <Row className="mb-2">
                            <h3 className="fs-5">{props.title}</h3>
                        </Row>
                            
                        <Row>
                            <Col xl="12">
                            <div className="table-responsive">
                                <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                
                                // selectRow={selectRow}
                                classes={
                                    "table align-middle table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                />
                                {productData.length === 0?<div className="emptyStateTable" style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    padding: '10px',
                                    paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}
                            </div>
                            </Col>
                        </Row>
                        </React.Fragment>
                    )
                    }
                    </ToolkitProvider>
                )
                }
                
                </PaginationProvider>
            </CardBody>
        </Card>
    )
}

export default DashTable