import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"

const StepsReach = () => {
  const [active, setActive] = useState(false)
  const [seoFocusEn, setSeoFocusEn] = useState("")
  const [seoFocusAr, setSeoFocusAr] = useState("")
  const [seoTitleEn, setSeoTitleEn] = useState("")
  const [seoTitleAr, setSeoTitleAr] = useState("")
  const [seoDescEn, setSeoDescEn] = useState("")
  const [seoDescAr, setSeoDescAr] = useState("")
  const [seoSlugEn, setSeoSlugEn] = useState("")
  const [seoSlugAr, setSeoSlugAr] = useState("")
  const [seoFaceTitleEn, setSeoFaceTitleEn] = useState("")
  const [seoFaceTitleAr, setSeoFaceTitleAr] = useState("")
  const [seoFaceDescEn, setSeoFaceDescEn] = useState("")
  const [seoFaceDescAr, setSeoFaceDescAr] = useState("")
  const [facebookImage, setFacebookImage] = useState(null)

  return (
    <div>
      <Form className="border-0 p-0">
        <Row>
          <Col className="">
            <Form className="border-0 pb-2">
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title Of Steps Cards (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Title Steps Cards"
                      value={seoFocusEn}
                      // onChange={e =>
                      //   setSeoFocusEn(e.target.value)
                      // }
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title Of Steps Cards (arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={seoFocusAr}
                      // onChange={e =>
                      //   setSeoFocusAr(e.target.value)
                      // }
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Sub Title Of Steps Cards (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title Steps Cards"
                      value={seoFocusEn}
                      // onChange={e =>
                      //   setSeoFocusEn(e.target.value)
                      // }
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Sub Title Of Steps Cards (arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={seoFocusAr}
                      // onChange={e =>
                      //   setSeoFocusAr(e.target.value)
                      // }
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "25px -20px 25px -20px" }} />
              <Row>
                <Col xs="12">
                  <div>
                    <Label htmlFor="productdesc">Icon Of Card1</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      onChange={e => handleFacebookFiles(e, e.target.files)}
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title of Card1 (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title of Cards"
                      value={seoFocusEn}
                      // onChange={e =>
                      //   setSeoFocusEn(e.target.value)
                      // }
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Title of Card1 (arabic)</Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={seoFocusAr}
                      // onChange={e =>
                      //   setSeoFocusAr(e.target.value)
                      // }
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card1 (English)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="Enter your description here"
                      value={seoDescEn}
                      onChange={e => setSeoDescEn(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card1 (Arabic)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="اكتب وصف هنا"
                      value={seoDescAr}
                      onChange={e => setSeoDescAr(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "25px -20px 25px -20px" }} />

              <Row>
                <Col xs="12">
                  <div>
                    <Label htmlFor="productdesc">Icon Of Cards2</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      // onChange={e =>
                      // handleFacebookFiles(e, e.target.files)
                      // }
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title of Card2 (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title of Cards"
                      value={seoFocusEn}
                      // onChange={e =>
                      //   setSeoFocusEn(e.target.value)
                      // }
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Title of Card2 (arabic)</Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={seoFocusAr}
                      // onChange={e =>
                      //   setSeoFocusAr(e.target.value)
                      // }
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card2 (English)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="Enter your description here"
                      value={seoDescEn}
                      onChange={e => setSeoDescEn(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card2 (Arabic)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="اكتب وصف هنا"
                      value={seoDescAr}
                      onChange={e => setSeoDescAr(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "25px -20px 25px -20px" }} />

              <Row>
                <Col xs="12">
                  <div>
                    <Label htmlFor="productdesc">Icon Of Card3</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      // onChange={e =>
                      // handleFacebookFiles(e, e.target.files)
                      // }
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title of Card3 (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title of Cards"
                      value={seoFocusEn}
                      // onChange={e =>
                      //   setSeoFocusEn(e.target.value)
                      // }
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Title of Card3 (arabic)</Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={seoFocusAr}
                      // onChange={e =>
                      //   setSeoFocusAr(e.target.value)
                      // }
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card3 (English)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="Enter your description here"
                      value={seoDescEn}
                      onChange={e => setSeoDescEn(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card3 (Arabic)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="اكتب وصف هنا"
                      value={seoDescAr}
                      onChange={e => setSeoDescAr(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Form>
      <hr style={{ margin: "25px -20px 20px -20px" }} />

      <Row className="mt-3">
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default StepsReach
