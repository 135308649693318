import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap';
import AdminPagination from './Components/AdminPagination'
import { useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import './configration.scss'
import './datatables.scss'
import './specification.scss'
import './AddInnerPageResource.scss'

const BlogsArticleSteps = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const sizePerPage = 10;
    const [page, setPage] = useState(1)
    const [totalSize, setTotalSize] = useState("10")
    const [filter, setFilter] = useState(null)
    const [data, setData] = useState([])
    
    

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
      },
      {
        dataField: "image",
        text: "Image",
        sort: true,
      },
      {
        dataField: "titleEn",
        text: "Title (English)",
        sort: true,
      },
      {
        dataField: "Author",
        text: "Author",
        sort: true,
      },
      {
        dataField: "Category",
        text: "Categories",
        sort: true,
      },

      {
        dataField: "Viwers",
        text: "Views",
        sort: true,
      },
      {
        dataField: "Links",
        text: "# Of Links",
        sort: true,
      },
      {
        dataField: "Videos",
        text: "# Of Videos",
        sort: true,
      },
  
     
      {
        dataField: "actions",
        text: "Actions",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Created On",
        sort: true,
      },
    ]

    function deleteArticle(id) {
        axios.post(`${process.env.REACT_APP_API}/dashboard/blog/delete`,{id},{
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
        }).then(res => {
            toast.success(res.data.message)
            setData(data => data.filter(d => d.id !== id))
        })
    }


    useEffect(() => {
        dispatch(setTitle("Blog Articles"))
        axios.get(`${process.env.REACT_APP_API}/dashboard/blog`,{
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }).then(res => {
          setData(res.data.data.map( data => {
            return (
                {...data,
                    image: <img src={data.image} style={imageStyle} alt=""/>,
                    Author:<span >{data?.Author?.nameEn} - {data?.Author?.positionEn}</span>,

                    Category: data?.Categories.length !== 0? data?.Categories.map((c,i)=> <span key={i} className={`${c.nameEn===1?'':'image-opacity'}`}  style={{backgroundColor: 'rgb(216 216 216)',
                    color: 'rgb(0, 0, 0)',
                    borderRadius: '45px',
                    padding: '5px 20px',
                    margin: '0px 2px',
                    display: 'inline-block'}}>{c.nameEn}</span>
                    ):<span className="fs-4">-</span>,

                  
                   
                    actions: (
                        <div className="d-flex">
                          <button
                            style={editBtn}
                            onClick={() => history.push(`/blogs/article-steps/article-form?itemId=${data.id}`)}
                          >
                            <i
                              className="bx bx-edit fs-4 pr-1"
                              style={{ marginRight: "10px" }}
                            ></i>{" "}
                            Edit
                          </button>
                          <button
                            style={deleteBtn}
                            onClick={e => deleteArticle(data.id)}
                          >
                            <i
                              className="bx bx-trash fs-4"
                              style={{ marginRight: "10px" }}
                            ></i>{" "}
                            Delete
                          </button>
                        </div>
                      ),
                }
            )
          }));
        })
    }, [])


  return (
    <>
        <Card>
            <CardBody>
                sd
            </CardBody>
        </Card>
        <Card>
            <CardBody>
                <AdminPagination 
                    data={data} 
                    page={page}
                    setFilter={setFilter}
                    keyFiled='id'
                    sizePerPage={sizePerPage} 
                    totalSize={totalSize}
                    url=''
                    none={false}
                    onTableChange={(type, { page }) => {
                        setPage(page)
                    }} 
                    columns={columns}
                />
            </CardBody>
        </Card>
    </>
    
  )
}

export default BlogsArticleSteps

 const deleteBtn = {
    background: "transparent",
    border: "none",
    color: "#D91414",
    display: "flex",
    alignItems: "center",
    gap: "0px",
  }
  
  const editBtn = {
    background: "transparent",
    border: "none",
    display: "flex",
    alignItems: "center",
    gap: "0px",
  }
  
  const imageStyle = {
    width: "60px",
    height: "60px",
    display: "block",
    objectFit: "contain",
  }
 