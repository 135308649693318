/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link, useHistory } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "../specification.scss"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

const AddCategoryFormResources = props => {
  let p = props
  const [name_en, setNameEn] = useState("")
  const [nameEnError, setNameEnError] = useState(null)
  const [descriptiopnEnError, setDescriptiopnEnError] = useState(null)
  const [nameArError, setNameArError] = useState(null)
  const [descriptiopnArError, setDescriptiopnArError] = useState(null)
  const [name_ar, setNameAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [color, setColor] = useState("#ffffff")

 

  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      nameEn: name_en,
      nameAr: name_ar,
      descriptionEn: descEn,
      descriptionAr:descAr,
      color: color,
      id,
    }

    for (let key in data) {
      formData.append(key, data[key])
    }
    let method = props.category? `/edit`: ``
    
    axios({
      method: 'POST',
      url:`${process.env.REACT_APP_API}/dashboard/blog/configurations/category${method}`,
      data: formData,
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        'Content-Type': 'application/json'
    }
    }).then(res => {
        props.getResourceData()
        toast.success(`Category ${props.category?'Updated':"Added"} Success`, {position: "top-right"})
        setLoading(false)
        props.setActive(false)
        props.setCategory(null)
        props.getMain() 
      })
      .catch((error)=>{
        console.log(error.response.data.error);
          setLoading(false)
          if(error.response.data.error != null){
            if(error.response.data.error.descriptionEn != null){
              error.response.data.error.descriptionEn.map(error =>{
                setDescriptiopnEnError(error)
              })
            }
            if(error.response.data.error.nameEn != null){
              error.response.data.error.nameEn.map(error =>{
                setNameEnError(error)
              })
            }
          }
      })
    
  }

  useEffect(() => {
    if (p.category) {
      setNameEn(p.category.nameEn)
      setNameAr(p.category.nameAr)
      setDescEn(p.category.descriptionEn)
      setDescAr(p.category.descriptionAr)
      setId(p.category.id)
      setColor(p.category.color)
      return
    }
    
  }, [p.category])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "550px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            Category
          </div>
          <button onClick={() => (props.setActive(false), props.setCategory(null))}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
          <Row className="mt-3">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Main Title (English)</Label>
                <Input
                  name="nameEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                  style={{ border: nameEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  nameEnError ? 
                  <span className="fs-6 text-danger">
                    { nameEnError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="">
                <Label htmlFor="productdesc">Main Title (Arabic)</Label>
                <Input
                  name="nameAr"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                
              </div>
            </Col>
          </Row>
          
          
          <Row className="mt-3">
            <Col sm="12">
              <div>
                <Label htmlFor="productdesc">Description (English)</Label>
               
                <ReactQuill
                  theme='snow'
                  modules={modulesQuill}
                  formats={formatsQuill}
                  value={descEn}
                  onChange={e => setDescEn(e)}
                />
              </div>
              {
                  descriptiopnEnError ? 
                  <span className="fs-6 text-danger">
                  { descriptiopnEnError }
                  </span> : ''
                }
            </Col>
            <Col sm="12" className="mt-3">
              <div>
                <Label htmlFor="productdesc">Description (Arabic)</Label>
                <ReactQuill
                theme='snow'
                modules={modulesQuill}
                formats={formatsQuill}
                value={descAr}
                onChange={e => setDescAr(e)}
              />
              </div>
              {
                  descriptiopnArError ? 
                  <span className="fs-6 text-danger">
                  { descriptiopnArError }
                  </span> : ''
                }
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
                <div className="d-flex">
                    <Label htmlFor="colorID">Color</Label>
                </div>
                <div className="d-flex">
                    <Input
                        type="color"
                        className="form-control colorInput"
                        name="backGroundColor"
                        value={color}
                        onChange={e => setColor(e.target.value)}
                        style={{width: "60px"}} 
                    />
                    <Input
                        type="text"
                        className="color__name rounded-0"
                        name="backGroundColor"
                        value={color}
                        onChange={e => setColor(e.target.value)}
                    />
                </div>
            </Col>
          </Row>

        </div>

        <div className="foot_form">
          <button className="cancel" onClick={() => (props.setActive(false), props.setCategory(null))}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
const imageStyle = {
  width: "60px",
  height: "60px",
  display: "block",
  objectFit: "contain",
}
const tdStyle = { 
  whiteSpace: "nowrap",
  verticalAlign: "middle"
}

export default AddCategoryFormResources

const modulesQuill = {
  toolbar: {
    container: [
      [{ "header": [1, 2, 3, 4, 5, 6, false] }],
      [{ "font": [] }],
      [{ "align": [] }],
      ["bold", "italic", "underline"],
      [{ "list": "ordered" }, { "list": "bullet" }, { "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", "custom-color"] }, { "background": [] }, "link", "emoji"]
    ],
    handlers: {
      "color": function(value) {
        if (value === "custom-color") value = window.prompt("Enter Hex Color Code")
        this.quill.format("color", value)
      }
    }
  },
  keyboard: {
    bindings: {
      tab: false,
      custom: {
        key: 13,
        shiftKey: true,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      },
      handleEnter: {
        key: 13,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      }
    }
  }
}

const formatsQuill = [
  "header", "font", "size",
  "bold", "italic", "underline", "strike", "blockquote",
  "list", "bullet", "indent", "align",
  "link", "image", "background", "color", "emoji"
]