import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import "./configration.scss"
import { useDispatch, useSelector } from "react-redux"
import { setTitle } from "../../../store/actions"
import axios from "axios"
import CategoryResources from "./Components/CategoryResources"
import AuthorResources from "./Components/AuthorResources"
import EmailShot from "./Components/EmailShot"
import WebsiteSetting from "./Components/WebsiteSetting"




const BlogOptions = () => {
  const dispatch = useDispatch()
  const [categories, setCategories] = useState(null)
  const [subCategories, setSubCategories] = useState([])
  const [tags, setTags] = useState([])
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const user = useSelector(state => state.UserData.user)
  
  
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  
  function getMain() { 
    axios
    .get(
    `${process.env.REACT_APP_API}/dashboard/blog/configurations/category`,
      {
        headers: {
          Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }
  )
  .then(res => {
    setCategories(res.data.data)
    })
  .catch(err => {
  })
  }


  useEffect(() => {
    dispatch(setTitle("Blog Configurations"))
    getMain()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | Blog</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row>
              <Col md="3">
                <Sticky topOffset={-300} >
                  <Nav
                    pills
                    className="flex-column justify-content-between navs_aside"
                  >
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Categories
                      </NavLink>
                    </NavItem>
                    {/* <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "2",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        Tags
                      </NavLink>
                    </NavItem> */}
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "3",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("3")
                        }}
                      >
                        Authors
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "4",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("4")
                        }}
                      >
                        News Letter Email Shot
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "5",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("5")
                        }}
                      >
                        Website Setting
                      </NavLink>
                    </NavItem>
                    

                  </Nav>
                </Sticky>
              </Col>
              <Col md="9">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <CategoryResources
                      roles={user?.roleAccess[3]}
                      getMain={getMain}
                    />
                  </TabPane>

                  {/* <TabPane tabId="2">
                    <TagResources
                      roles={user?.roleAccess[3]}
                      getMain={getMain}
                    />
                  </TabPane> */}
                  <TabPane tabId="3">
                    <AuthorResources
                      roles={user?.roleAccess[3]}
                      getMain={getMain}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <EmailShot
                      roles={user?.roleAccess[3]}
                      getMain={getMain}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <WebsiteSetting />
                  </TabPane>
                  
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogOptions


