/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row, Container } from "reactstrap"

const Cards = ({ data, title, cols }) => {
  return (
    <>
      <h3 className="fw-bolder text-dark mb-2">{title}</h3>
      <Row>
        {data?.map((item, i) => (
          <Col xl={cols} md={4} sm={6} xs={12} key={i}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-dark fw-bold  mb-2 fs-6">{item.title}</p>
                    <h5 className="mb-0 fw-bolder fs-4">
                      {item.total} {" "}
                      {item.desc}
                    </h5>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default Cards
