import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import ImagesForm from '../BodySection/ImagesForm'
import ImportantParagraphForm from '../BodySection/ImportantParagraphForm'
import ParagraphForm from '../BodySection/ParagraphForm'
import TitlesForm from '../BodySection/TitlesForm'
import ReactLoading from "react-loading"
import Tabs from '../BodySection/Tabs'
import { DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import 'react-quill/dist/quill.snow.css'
import axios from 'axios'
import { toast } from 'react-toastify'

const DataSchema = { 
  title: {
    titleEn: "",
    titleAr: "",
    fontSizeID:"",
    fontTypeID:"",
    Type: "Title",
    RowID:null
  },
  description: {
    descriptionEn: "",
    descriptionAr: "",
    Type: "Paragraph",
    RowID:null 
  },
  importantDescription: {
    descriptionEn: "",
    descriptionAr: "",
    color: "#000000",
    Type: "ImportantParagraph",
    RowID:null 
  },
  image: {
    Images: [],
    mode: "",
    Type: "Images",
    RowID:null
  }
}

const BodySection = ({modulesQuill, formatsQuill, font, id, itemData, getData, prevSelectImage}) => {
  const [active, setActive] = useState(false)
  const [data, setData] = useState([])


  function addComponentHandler (type) { 
    const selectedType = type == "Title"?DataSchema.title:
                         type == "Paragraph"?DataSchema.description:
                         type == "ImportantParagraph"?DataSchema.importantDescription:
                         DataSchema.image
    setData(data => data.concat(selectedType))
    console.log(data);
  }

  function handleDrag(event) {
    const {active,over} =event
    if(active.id!=over.id){
      const activeIndex = active.data.current.sortable.index
      const overIndex = over.data.current.sortable.index
      const  newArrange = arrayMove(data,activeIndex,overIndex)
      const ids = newArrange.map(item => item.RowID?item.RowID:null)
      setData(data => newArrange)
      axios.post(`${process.env.REACT_APP_API}/dashboard/uxProcess/subCategory/details/arrange`, {ids, subCategoryID: id},{
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        toast.success(res.data.message);
      })
    }
  }

  function deleteRow (RowID) {
    axios.post(`${process.env.REACT_APP_API}/dashboard/uxProcess/subCategory/details/delete`, {subCategoryID:id, RowID},{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
    .then(res => {
      setActive(false)
      toast.success(res.data.message);
    })
  }


  function submit () {
    setActive(true)
    let formData = new FormData()

    data.forEach((item, i) => {
      let keys = Object.keys(item) 
      keys.forEach(key => {
        key !== "Images"? formData.append(`rows[${i}][${key}]`, item[key]):
        item[key].map((img, j) => formData.append(`rows[${i}][${key}][${j}]`, img.id?img.image:img))
      })
    })

    formData.append(`subCategoryID`, id)

    id?axios.post(`${process.env.REACT_APP_API}/dashboard/uxProcess/subCategory/details`, formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
    .then(res => {
      setActive(false)
      toast.success(res.data.message);
      getData()
    }):toast.error(`Please Submit Step Information First 😒`)
  }

  
  useEffect(() => {
    if(itemData) { 
     setData(itemData.Collections.map(item => ({...item, ...item.compmonent})))
    }
  }, [itemData])

  return (
    <>
      <Tabs addComponentHandler={addComponentHandler}/>
      {data.length != 0 &&<Card>
          <CardBody>
              <DndContext onDragEnd={handleDrag}>
                <SortableContext items={data.map((d,i) => i+1)}>
                    {data.map((item, index) => {
                      return item.Type == "Title"?<TitlesForm key={index} sortId={index+1} font={font} data={data[index]} setData={setData} index={index} deleteRow={deleteRow}/>:
                      item.Type == "Paragraph"?<ParagraphForm key={index} sortId={index+1} options={{modulesQuill, formatsQuill}}  data={data[index]} setData={setData} index={index} deleteRow={deleteRow}/>:
                      item.Type == "ImportantParagraph"?<ImportantParagraphForm key={index} sortId={index+1} options={{modulesQuill, formatsQuill}}  data={data[index]} setData={setData} index={index} deleteRow={deleteRow}/>:
                      item.Type == "Images"?<ImagesForm prevSelectImage={prevSelectImage} key={index} sortId={index+1}  data={data[index]} setData={setData} index={index} deleteRow={deleteRow}/>:null
                    })}
                </SortableContext>
              </DndContext>

              {/*************Submit button*************/}
              <hr style={{ margin: "20px -20px 20px -20px" }} />
              <Row>
                <Col className="d-flex justify-content-end gap-2">
                  <div className="save__btn top m-0 ">
                    <button type='button' onClick={submit}>
                      {active ? (
                        <ReactLoading
                          type={"spin"}
                          color={"#ffffff"}
                          height={30}
                          width={30}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
          </CardBody>
      </Card>}
    </>
  )
}

export default BodySection