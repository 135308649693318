import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../../store/actions";
import { useHistory } from "react-router-dom";
import UiGenerator from "./Components/UiGenerator";
import ContentGenerator from "./Components/ContentGenerator";
import Design365 from "./Components/Design365";
import Products from "./Components/Products/Products";
// import Products from "./Components/Products";
import Links from "./Components/Links";
import LearnUiComp from "./Components/LearnUiComp";
import CVComp from "./Components/CVComp";

const PagesUiLearn = () => {
    const dispatch = useDispatch()
    const history  = useHistory()
    const   user = useSelector(state => state.UserData.user)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };


    function userChanges(user, type) {
        if(type === "active"){
            setActive(users => users.concat(user))
            setUnActive(users => users.filter(u => u.key !== user.key ))
        } else if(type === "unactive"){
            setActive(users => users.filter(u => u.key !== user.key ))
            setUnActive(users => users.concat(user))
        }else if(type === "del") {
            setActive(users => users.filter(u => u.key !== user.key ))
        }else {
            setPending(users => users.filter(u => u.key !== user.key ))
            setUnActive(users => users.concat(user))
        }
    }

    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/dashboard')
            return;
        } 
    }, [user])



    useEffect(() => {   
        dispatch(setTitle("Pages"))
    }, [])

    return (
        <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Pages | Etoy App</title>
        </MetaTags>
        <Container fluid>
        <Col>
            <Row>
            {/* <Col md="3">
            <Sticky topOffset={-70} style={{top: "70px"}}>
                <Nav pills className="flex-column justify-content-between navs_aside">

                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                            Learn Ui
                        </NavLink>
                    </NavItem>
                </Nav>
                </Sticky>
            </Col> */}
            <Col md="12">
                <TabContent
                activeTab={verticalActiveTab}
                className="text-muted table__content mt-4 mt-md-0"
                >
                    <TabPane tabId="1">
                        <LearnUiComp />
                    </TabPane>
                </TabContent>
            </Col>
            </Row>
        </Col>
        </Container>
        </div>
    </React.Fragment>
)
}

export default PagesUiLearn



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}