/* eslint-disable react/prop-types */
import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from "reactstrap"
import './emailshot.scss'

import EmailForm from './EmailForm'
import axios from "axios";

const EmailShot = (props) => {
  const [active, setActive] = useState(false)
  const [data, setData] = useState(null)
  function clickHandle () {
    setActive(!active)
  }

  useEffect(() => {
    setData(props.email);
    axios.get(`${process.env.REACT_APP_API}/dashboard/blog/configurations/emailShot`, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
  .then(response => {
    setData(response.data.data);
  })
  }, [])


  return (
    <React.Fragment>
    <div className="page-content p-0">
        <MetaTags>
        <title>Starter Page | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
        <EmailForm clickHandle={clickHandle} active={active} data={data}/> 
        <Row className="email_shot_container m-0 ">
            <Col lg={8} xs={9} className="p-0 m-0 mr-1 " style={{background: "#fff"}}>
            <Row className="email_head m-0">
            <img src={data?.logo} alt='' style={{width: "50%"}}/>


                <h2 className="email_title">Thank you for your interest in the eToy App; We hope you enjoy your journey with us</h2>
                <h2 className="fs-5 text-center mb-3" style={{lineHeight: '24px !important', width: "70%", margin: "auto"}}>Download eToy characters now and have fun with your kids</h2>
                <Col xs={12}>
                    <button className="email_code" style={{background: '#26E71E',color: '#000', fontSize: '16px'}}>Download Now</button>
                </Col>
            </Row>
            <Row className="email_btns m-0">
                
                <Col>
                    <img src={data?.image} alt='' style={{width: "60%"}}/>
                    <Row>
                        <p className="d-block text-center  fs-5" style={{color: "#000"}}>earn. exchange. encourage</p>
                    </Row>
                    
                    <Row>
                        <p className="d-block text-center color-black-500 fs-5 mb-0" style={{color: "#000",fontWeight: "600"}}>For questions or support, reach out to</p>
                    </Row>
                    <Row>
                        <p className="d-block text-center fs-5 fw-bolder mb-5" style={{color: "#26E71E",fontWeight: "600"}}>support@etoyapp.com</p>
                    </Row>
                </Col>
            </Row>
            <Row className="email_s m-0">
              <Col className="pt-3">
                <div className="e_social">
                <div className="d-flex align-items-center">
                    <i className='bx bxl-facebook fs-3 '></i>
                    Facebook
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <i className='bx bxl-instagram fs-3 '></i>
                    Instagram
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="e_social fs-6">
                  <div >
                  <u>Terms & Conditions</u>
                  </div>
              
                  <div>
                    <u>Covid-19 Policy</u>
                  </div>
                  <div>
                    <u>Point Policy</u>
                  </div>
                  <div>
                    <u>Photo Guideline</u>
                  </div>
                  </div>
              </Col>
              
            </Row>
            <Row className="email_foot m-0">
                <Col className="p-0 m-0">
                <p >Please do not reply to this email. Emails sent to this address will not be answered. Copyright © 2012-2022 <u><a href="www.layouti.com" style={{color: "#4c8866"}}> Layouti Design</a></u> All Rights Reserved This email was sent from <span style={{color: "#4c8866"}}>www.etoyapp.com</span></p>
                </Col>
            </Row>
            </Col>
            <Col xs={3} className="justify-content-end p-0 m-0 mb-2 ml-2">
                  <button className="edit_mail" onClick={clickHandle}><i className='bx bx-edit-alt fs-4'></i>Edit</button>
                </Col>
        </Row>
        </Container>
    </div>
    </React.Fragment>
  )
}

export default EmailShot