import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"

import { Card, CardBody, Col, Row, Container } from "reactstrap"
import Cards from "./Cards"
import DashTable from "./DashTable"
import SplineArea from "./SplineArea"
import SplineAreaMobile from "./SplineAreaMobile"

import axios from "axios"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import axiosConfig from "axiosConfig"
function Dashboard() {
  const dispatch = useDispatch()
  const [categories, setCategories] = useState([])
  const [data, setData] = useState(null)

  const [usersData, setUsersData] = useState([
    { title: "Total Of Users", total: 0, desc: "Users" },
    { title: "Total Of Visitors Design Process", total: 0, desc: "Users" },
    { title: "Total Of Visitors Articles", total: 0, desc: "Users" },
    { title: "Total Of Subscription", total: 0, desc: "Users" },
  ])
  const [generalData, setGeneralData] = useState([
    { title: "Total Of Country", total: 0, desc: "Country" },
    { title: "Total Of Categories", total: 0, desc: "Categories" },
    { title: "Total Of Sub-Categories", total: 0, desc: "Sub-Categories" },
    { title: "Total Of Articles", total: 0, desc: "Articles" },
    { title: "# of click on logo", total: 0, desc: "Users" },
    { title: "# of click on social", total: 0, desc: "Users" },
    { title: "# of click on Footer", total: 0, desc: "Users" },
    { title: "# of click on drawer", total: 0, desc: "Users" },
  ])
  const [articleData, setArticleData] = useState([
    { title: "Total Of Layouti Blog", total: 0, desc: "Articles" },
    { title: "Total Of Case Study", total: 0, desc: "Articles" },
    { title: "Total Of Processes & Tools", total: 0, desc: "Articles" },
    { title: "Total Of UI Design", total: 0, desc: "Articles" },
    { title: "Total Of UX Design", total: 0, desc: "Articles" },
    { title: "Total Of Technology", total: 0, desc: "Articles" },
  ])

  const [tableOne, setTableOne] = useState([])
  const [tableTwo, setTableTwo] = useState([])


  const columns = [
    {
      dataField: "name",
      text: "Sub-Categories",
      sort: true,
    },
    {
      dataField: "Viewrs",
      text: "# of View",
      sort: true,
    },
    {
      dataField: "Countries",
      text: "# of Countries",
      sort: true,
    },
    {
      dataField: "Links",
      text: "# of Links",
      sort: true,
    },
    {
      dataField: "Videos",
      text: "# of Video",
      sort: true,
    },
    {
      dataField: "DownloadFiles",
      text: "# of Download",
      sort: true,
    },
  ]

  const CountriesColumns = [
    {
      dataField: "name",
      text: "Countries Name",
      sort: true,
    },
    {
      dataField: "totViews",
      text: "Total Views",
      sort: true,
    },
    {
      dataField: "numViews",
      text: "Number of Views",
      sort: true,
    },
  ]

  function getData() {
    axios
      .get(`${process.env.REACT_APP_API}/dashboard/uxProcess/analysis`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setUsersData([
          {
            title: "Total Of Users",
            total: res.data.data.UserData.totUsers,
            desc: "Users",
          },
          {
            title: "Total Of Visitors Design Process",
            total: res.data.data.UserData.totOfVisitorsDesignProcess,
            desc: "Users",
          },
          {
            title: "Total Of Visitors Articles",
            total: res.data.data.UserData.totOfVisitorsArticles,
            desc: "Users",
          },
          {
            title: "Total Of Subscription",
            total: res.data.data.UserData.totalOfSubscription,
            desc: "Users",
          },
        ])

        setGeneralData([
          {
            title: "Total Of Country",
            total: res.data.data.GeneralData.totCountries,
            desc: "Country",
          },
          {
            title: "Total Of Categories",
            total: res.data.data.GeneralData.totCategory,
            desc: "Categories",
          },
          {
            title: "Total Of Sub-Categories",
            total: res.data.data.GeneralData.totSubCategory,
            desc: "Sub-Categories",
          },
          {
            title: "Total Of Articles",
            total: res.data.data.GeneralData.totArticles,
            desc: "Articles",
          },
          {
            title: "# of click on logo",
            total: res.data.data.GeneralData.totLogo,
            desc: "Users",
          },
          {
            title: "# of click on social",
            total: res.data.data.GeneralData.totSocial,
            desc: "Users",
          },
          {
            title: "# of click on Footer",
            total: res.data.data.GeneralData.totFooter,
            desc: "Users",
          },
          {
            title: "# of click on drawer",
            total: res.data.data.GeneralData.totDrawer,
            desc: "Users",
          },
        ])

        setArticleData(
          res.data.data.ArticlesData.map(item => ({
            title: "Total Of " + item.name,
            total: item.Blogs,
            desc: "Articles",
          }))
        )
      })
  }

  function getCountryTableData() {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/uxProcess/analysis/countries`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setTableTwo(
          res.data.data.map(item => ({
            ...item,
            numViews: (
              <div className="my-2 d-flex gap-2">
                {item.numViews.map((cat, i) => (
                  <span
                    style={{
                      padding: "5px 20px",
                      borderRadius: "50px",
                      background: "#DDD",
                      color: "#071C3E",
                    }}
                    key={i}
                  >
                    {cat}
                  </span>
                ))}
              </div>
            ),
          }))
        )
      })
  }

  function getCategoriesData() {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/uxProcess/analysis/categories `,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setCategories(
          res.data.data.map(item => ({
            label: (
              <span className={"fs-5 "} style={{ color: "#3aaa35" }}>
                {item.name}
              </span>
            ),
            options: item.subCat.map(cat => ({
              label: cat.name,
              value: cat.id,
            })),
          }))
        )
      })
  }

  function getCategoriesTableData(id) {
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/uxProcess/analysis/categories `,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setTableOne(res.data.data)
      })
  }

  useEffect(() => {
    dispatch(setTitle("Dashboard"))
    getData()
    getCountryTableData()
    getCategoriesData()
    getCategoriesTableData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Academy</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Cards data={usersData} title={"User Data"} cols={3} />
          </Row>
          <Row className="mt-3">
            <Cards data={generalData} title={"General Data"} cols={3} />
          </Row>

          <Row className="mt-3">
            <Cards data={articleData} title={"Articles Data"} cols={4} />
          </Row>
          <Row className="mb-3">
            <Col xs={4} className="mt-3">
              <Select
                options={categories}
                placeholder="Categories"
                onChange={e => getCategoriesTableData(e.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DashTable
                title={"Categories"}
                columns={columns}
                data={tableOne}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <DashTable
                title={"Countries"}
                columns={CountriesColumns}
                data={tableTwo}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
