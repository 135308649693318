import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap'
import PulseLoader from "react-spinners/PulseLoader";
import axios from 'axios'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import prettyBytes from 'pretty-bytes';
import Lightbox from 'react-image-lightbox';
import axiosConfig from 'axiosConfig';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';


function DashboardSettings() {
    
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [buttonColor, setButtonColor] = useState('#000000')
    const [textSideBarColor, setTextSideBarColor] = useState('#000000')
    const [sideBarColor, setSideBarColor] = useState('#000000')
    const [favIcon, setFavIcon] = useState(null)
    const [icon, setIcon] = useState(null)
    const [isOpen, setisOpen] = useState(false);
    const [preview, setPreview] = useState(null)
    const [success_msg, setsuccess_msg] = useState(false)
    const [msg, setMsg] = useState("")
    function onSubmit (e) {
        e.preventDefault() 
        setLoading(true)
        document.documentElement.style.setProperty('--main-color', sideBarColor);
        let formData = new FormData()
        formData.append('icon',icon)
        formData.append('favIcon',favIcon)
        formData.append('buttonColor',buttonColor)
        formData.append('textSideBarColor',textSideBarColor)
        formData.append('sideBarColor',sideBarColor)
   
        document.documentElement.style.setProperty('--main-color', buttonColor);
        document.documentElement.style.setProperty('--side-color', sideBarColor);
        document.documentElement.style.setProperty('--text-color', textSideBarColor);
        
        axiosConfig.post(`/dashboard/configurations/dashboardSetting/save`, formData, {
            headers: { 
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
              }
        })
        .then(res => {
            setLoading(false)
            setMsg(`Dashboard Settings Updated Successfully`)
            setsuccess_msg(true)
            getData()
            
        }).catch(err=>{
            setLoading(false)
            toast.error('Something went wrong')
        })
    }
    let getData = ()=>{
        axiosConfig.get(`/dashboard/configurations/dashboardSetting`, {
            headers: { 
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
              }
            })
            .then(response => {
                let res = response.data.data;
                setButtonColor(res.buttonColor)
                setTextSideBarColor(res.textSideBarColor)
                setSideBarColor(res.sideBarColor)
                setFavIcon(res.favIcon)
                setIcon(res.icon)
                if(res.error) {
                    return;
                }
            })
            .catch(function (error) {
            });
        }
        useEffect(()=>{
            getData()
        },[])

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: prettyBytes(file.size)
            })
        )
        setFavIcon(files[0])
      }
    function handleAcceptedFilesLogo(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: prettyBytes(file.size)
            })
        )
        setIcon(files[0])
      }
      function deleteImage(e) {
        e.preventDefault()
        setFavIcon(null)
    }
      function deleteImageLogo(e) {
        e.preventDefault()
        setIcon(null)
    }

    

    function setOpen(e) {
        e.preventDefault()
        if(typeof(favIcon) == "string"){
            setPreview(favIcon)
        }else{
            setPreview(favIcon.preview)
        }
        
        setisOpen(true)
    }
    function setOpenLogo(e) {
        e.preventDefault()
        if(typeof(icon) == "string"){
            setPreview(icon)
        }else{
            setPreview(icon.preview)
        }
        
        setisOpen(true)
    }
    
  return (
    <div >
       
                {isOpen ? (
                    <Lightbox
                        mainSrc={preview}
                        enableZoom={false}
                        onCloseRequest={() => {
                        setisOpen(!isOpen);
                        }}
                    />
                    ) : null}
                    {success_msg ? (
                    <SweetAlert
                        title={msg}
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                        setsuccess_msg(false)
                        }}
                        onCancel={() => {
                        setsuccess_msg(false)
                        }}
                    >
                    </SweetAlert>
                    ) : null}
                <Card>
                    <CardBody className='px-0'>
                        <Form className='border-0 pb-3 px-3'>
                           
                        <Row>
                                <Col>
                                    <div className="mb-3 ">
                                        <Label htmlFor="metatitle">Icon</Label>
                                        <div className="mb-3" >
                                            <Input 
                                                type="file"
                                                className="form-control form-control-lg "
                                                onChange={e => handleAcceptedFilesLogo(Object.values(e.target.files))}
                                                style={{borderRadius: 0}}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                    </div>
                                </Col>
                                {icon? <Row className="align-items-center mb-3" style={{paddingLeft: "14px", paddingRight: "0px"}}>
                                    <Col className="col-auto">
                                        <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={icon?.name}
                                        src={icon?.preview?icon.preview:icon}
                                        style={{objectFit: "cover"}}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                        to="#"
                                        className="text-muted "
                                        >
                                        {icon?.name}
                                        </Link>
                                        <p className="mb-0">
                                        <strong>{icon?.formattedSize}</strong>
                                        </p>
                                    </Col>
                                    <Col className='p-0'>
                                        <div className="image__options">
                                            <button className="p-0"  onClick={(e) => setOpenLogo(e)}><i className="far fa-eye  fs-5 p-0"></i></button>
                                            <button className='p-0' style={{color: "#ff3838"}} onClick={(e) => deleteImageLogo(e)}><i className='bx bx-trash fs-4'></i></button>
                                        </div>
                                    </Col>
                                </Row>:null}
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="metatitle">Fav Icon</Label>
                                        <div className="mb-3" >
                                            <Input 
                                                type="file"
                                                className="form-control form-control-lg "
                                                onChange={e => handleAcceptedFiles(Object.values(e.target.files))}
                                                style={{borderRadius: 0}}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                    </div>
                                </Col>
                                {favIcon? <Row className="align-items-center mb-3" style={{paddingLeft: "14px", paddingRight: "0px"}}>
                                    <Col className="col-auto">
                                        <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={favIcon?.name}
                                        src={favIcon?.preview?favIcon.preview:favIcon}
                                        style={{objectFit: "cover"}}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                        to="#"
                                        className="text-muted "
                                        >
                                        {favIcon?.name}
                                        </Link>
                                        <p className="mb-0">
                                        <strong>{favIcon?.formattedSize}</strong>
                                        </p>
                                    </Col>
                                    <Col className='p-0'>
                                        <div className="image__options">
                                            <button className="p-0"  onClick={(e) => setOpen(e)}><i className="far fa-eye  fs-5 p-0"></i></button>
                                            <button className='p-0' style={{color: "#ff3838"}} onClick={(e) => deleteImage(e)}><i className='bx bx-trash fs-4'></i></button>
                                        </div>
                                    </Col>
                                </Row>:null}
                            </Row>
                            
                            

                            
                            
                            <Row className='mt-3'>
                                <Col xs='12'> 
                                    <div className="inner-repeater mb-1">
                                        <div className="d-flex flex-column" >
                                            <Row className="d-flex  w-fill-available">
                                                <Col xs="12" className="d-flex flex-column">
                                                <Label className="fs-5 w-fit-content">Button Color</Label>
                                                <div className="d-flex w-100">
                                                    <Input 
                                                        type="color"
                                                        name='color'
                                                        value={buttonColor}
                                                        onChange={e => setButtonColor(e.target.value)}
                                                        style={{width: "70px", boxShadow: "none"}}
                                                        required
                                                        />
                                                    <Input 
                                                    type="text" 
                                                    className="color__name"
                                                    value={buttonColor}
                                                    onChange={e => setButtonColor(e.target.value)}
                                                    />
                                                </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs='12'> 
                                    <div className="inner-repeater mb-1">
                                        <div className="d-flex flex-column" >
                                            <Row className="d-flex  w-fill-available">
                                                <Col xs="12" className="d-flex flex-column">
                                                <Label className="fs-5 w-fit-content">Side Bar Color</Label>
                                                <div className="d-flex w-100">
                                                    <Input 
                                                        type="color"
                                                        name='color'
                                                        value={sideBarColor}
                                                        onChange={e => setSideBarColor(e.target.value)}
                                                        style={{width: "70px", boxShadow: "none"}}
                                                        required
                                                        />
                                                    <Input 
                                                    type="text" 
                                                    className="color__name"
                                                    value={sideBarColor}
                                                    onChange={e => setSideBarColor(e.target.value)}
                                                    />
                                                </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs='12'> 
                                    <div className="inner-repeater mb-1">
                                        <div className="d-flex flex-column" >
                                            <Row className="d-flex  w-fill-available">
                                                <Col xs="12" className="d-flex flex-column">
                                                <Label className="fs-5 w-fit-content">Text Side Bar Color</Label>
                                                <div className="d-flex w-100">
                                                    <Input 
                                                        type="color"
                                                        name='color'
                                                        value={textSideBarColor}
                                                        onChange={e => setTextSideBarColor(e.target.value)}
                                                        style={{width: "70px", boxShadow: "none"}}
                                                        required
                                                        />
                                                    <Input 
                                                    type="text" 
                                                    className="color__name"
                                                    value={textSideBarColor}
                                                    onChange={e => setTextSideBarColor(e.target.value)}
                                                    />
                                                </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                       
                                <div className='save__btn mb-0 px-3 pt-3' style={{borderTop:" 1px solid rgb(241 241 241)"}}>
                                    <button onClick={e => onSubmit(e)}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Save"}</button>
                                </div>
                        
                    </CardBody>
                </Card>
               
    </div>
  )
}

export default DashboardSettings