import {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS
} from "./actionTypes"

const INIT_STATE = {
  notifications: [],
  userCount: 0,
  toyCount: 0,
  notifyCount: 0,
  suggestionCount: 0,
  feedbackCount: 0,
  reviewCount: 0,
  reportCount: 0,
}

const Notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        notifications: Array.isArray(action.payload)?action.payload.concat(state.notifications):[action.payload].concat(state.notifications),
        userCount: action.payload.type === "user"?state.userCount+1:state.userCount,
        toyCount: action.payload.type === "toy"?state.toyCount+1:state.toyCount,
        notifyCount: Array.isArray(action.payload)?state.notifyCount:state.notifyCount+1,
        suggestionCount: action.payload.type === "suggestion"?state.suggestionCount+1:state.suggestionCount,
        feedbackCount: action.payload.type === "feedback"?state.feedbackCount+1:state.feedbackCount,
        reviewCount: action.payload.type === "review"?state.reviewCount+1:state.reviewCount,
        reportCount: action.payload.type === "report"?state.reportCount+1:state.reportCount,
      }
      case UPDATE_NOTIFICATIONS:
        return {
          notifications: state.notifications,
          notifyCount: action.payload.unreadNotificationCount ||action.payload.unreadNotificationCount == 0? action.payload.unreadNotificationCount:state.notifyCount,
          userCount: action.payload.unreadUserCount || action.payload.unreadUserCount == 0?action.payload.unreadUserCount:state.userCount,
          toyCount: action.payload.unreadToyCount || action.payload.unreadToyCount == 0?action.payload.unreadToyCount:state.toyCount,
          suggestionCount: action.payload.unreadSuggestionCount ||  action.payload.unreadSuggestionCount == 0? 0:state.suggestionCount,
          feedbackCount:  action.payload.unreadFeedbackCount || action.payload.unreadFeedbackCount == 0? action.payload.unreadFeedbackCount:state.feedbackCount,
          reviewCount: action.payload.unreadReviewCount || action.payload.unreadReviewCount == 0?action.payload.unreadToyCount:state.reviewCount,
          reportCount: action.payload.unreadReportCount || action.payload.unreadReportCount == 0 ?action.payload.unreadReportCount:state.reportCount,
        }
      default:
        return state
  }
}

export default Notifications