import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/General/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/General/Authentication/Login"

// Dashboard
import Dashboard from "../pages/General/Dashboard/index"
import BlogsArticleSteps from "pages/Blog/Article Steps/BlogsArticleSteps"
import BlogOptions from "pages/Blog/Options/BlogOptions"
import BlogSeoPage from "pages/Blog/SeoPage/BlogSeoPage"
import BlogPages from "pages/Blog/Pages/BlogPages"

import Subscription from "pages/Blog/Subscription/Subscription"
import BlogsArticleAddForm from "pages/Blog/Article Steps/Forms/BlogsArticleAddForm"


import OtionsSettings from "pages/Settings/AdminSettings/OtionsSettings"
import PagesUiLearn from "pages/UILearn/Pages/PagesUiLearn"
import PagesUXLearn from "pages/UXLearn/Pages/PagesUXLearn"
import PagesEbook from "pages/Ebook/Pages/PagesEbook"
import Registration from "pages/UILearn/Registration/Registration"
import Options from "pages/Settings/Options/Options"
import UxOptions from "pages/UxResources/Options/UxOptions"
import UiUxPages from "pages/UxResources/Pages/UiUxPages"
import UxSeoPage from "pages/UxResources/SeoPage/UxSeoPage"
import ArticleAddForm from "pages/UxResources/Article Steps/Forms/ArticleAddForm"
import ArticleSteps from "pages/UxResources/Article Steps/ArticleSteps"
import UXAnalysis from "pages/UxResources/Analysis/index"
import User from "pages/User/User"






const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  //uilearn
  { path: "/uilearn/pages", component: PagesUiLearn },

  { path: "/uilearn/registration", component: Registration },
  //uxlearn
  { path: "/uxlearn/pages", component: PagesUXLearn },
  //ebook
  { path: "/ebook/pages", component: PagesEbook },
  { path: "/users", component: User },
  //uiux
  { path: "/uiux/analysis", component: UXAnalysis },
  { path: "/uiux/article-steps", component: ArticleSteps},
  { path: "/uiux/article-steps/article-form", component: ArticleAddForm},
  { path: "/uiux/seo", component: UxSeoPage},
  { path: "/uiux/pages", component: UiUxPages },
  { path: "/uiux/configurations", component: UxOptions },


  //Blogs
  { path: "/blogs", component: BlogsArticleSteps },
  { path: "/blogs/subscription", component: Subscription},
  { path: "/blogs/options", component: BlogOptions },
  { path: "/blogs/seo", component: BlogSeoPage},
  { path: "/blogs/pages", component: BlogPages },
  { path: "/blogs/article-steps/article-form", component: BlogsArticleAddForm},


  
  
  //Admin Settings
  { path: "/adminsetting", component: OtionsSettings },
  
  //configurations
  { path: "/configurations", component: Options },
  
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]



const publicRoutes = [
  { path: "/login", component: Login },
]

export { publicRoutes, authProtectedRoutes }
