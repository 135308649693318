import React, { useState,useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Card, CardBody, Col, Row,Container } from "reactstrap";
import Cards from "./Cards";
import DashTable from "./DashTable";
import SplineArea from './SplineArea'
import SplineAreaMobile from './SplineAreaMobile'

import axios from "axios";
import Select from 'react-select'
import { useSelector, useDispatch } from "react-redux";
import {
    setTitle
} from "../../../store/actions";
import axiosConfig from "axiosConfig";
import CountryTable from "./CountryTable";
function Dashboard () {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.MainData.categories)
  const [data, setData]  = useState(null)
  const [info, setInfo]  = useState([])
  const [info2, setInfo2]  = useState([])
  const columns = [{
    dataField: 'id',
    text: 'Id',
    sort: true,
  }, {
    dataField: 'nameEn',
    text: 'Name (English)',
    sort: true
  },{
    dataField: 'subCategoriesCount',
    text: '# of SubCategories',
    sort: true,
  }, {
    dataField: 'Links',
    text: '# of Links',
    sort: true
  }, {
    dataField: 'Video',
    text: '# of Videos',
    sort: true
  },{
    dataField: 'DownloadFile',
    text: '# of DownloadFile',
    sort: true
  },  {
    dataField: 'Article',
    text: '# of Article',
    sort: true
  },{
    dataField: 'Viwers',
    text: '# of Views',
    sort: true,
  }];



  const userColumns = [{
    dataField: 'id',
    text: 'Id',
    sort: true,
  }, {
    dataField: 'nameEn',
    text: 'Name (English)',
    sort: true
  }, {
    dataField: 'nameAr',
    text: 'Name (Arabic)',
    sort: true
  },{
    dataField: 'code',
    text: 'Code',
    sort: true
  },{
    dataField: 'todayCount',
    text: 'Today',
    sort: true
  },{
    dataField: 'allCount',
    text: 'All',
    sort: true
  },{
    dataField: 'pages',
    text: 'Pages',
    sort: true
  }];

  function getData() {
    axios
      .get(`${process.env.REACT_APP_API}/dashboard/uxProcess/analysis`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setData(res.data.data)
        setInfo(res.data.data?.Categories)
        setInfo2(res.data.data?.Countries)
      })
      .catch(err => {
      })
  }
    

 

    useEffect(() => {
      getData()
      dispatch(setTitle("Analysis"))

      
  }, [categories])
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Ux Process</title>
          </MetaTags>
          <Container fluid>
            
            <Cards data={data}/>
            
            <Row>
              <Col>
                <DashTable title={"Categories"} columns={columns} data={info}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <CountryTable title={"Countries"} columns={userColumns} data={info2}/>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
}

export default Dashboard;
