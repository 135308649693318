import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import "./configration.scss"
import { useSelector, useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import { useHistory } from "react-router-dom"

import axiosConfig from "axiosConfig"
import AdminSettings from "./AdminSettings"
import DashboardSettings from "./Components/DashboardSettings/DashboardSettings"


const OtionsSettings = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)
  const history = useHistory()

  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  function userChanges(user, type) {
    if (type === "active") {
      setActive(users => users.concat(user))
      setUnActive(users => users.filter(u => u.key !== user.key))
    } else if (type === "unactive") {
      setActive(users => users.filter(u => u.key !== user.key))
      setUnActive(users => users.concat(user))
    } else if (type === "del") {
      setActive(users => users.filter(u => u.key !== user.key))
    } else {
      setPending(users => users.filter(u => u.key !== user.key))
      setUnActive(users => users.concat(user))
    }
  }

  useEffect(() => {
    if (user && user.role == 2) {
      history.push("/")
      return
    }
  }, [user])

  const [layoutiCategories, setLayoutiCategories] = useState([])

  
  

  function getLayoutiData() {
    axiosConfig
      .get(`/dashboard/configurations/layoutiCategoriesServices`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        console.log("res.datares.datares.sdataxres.data", res.data)
        setLayoutiCategories(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }
  useEffect(() => {
    dispatch(setTitle("Settings"))
    getLayoutiData() 
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | Etoy App</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row>
              <Col md="3">
                <Sticky topOffset={-70} style={{ top: "70px" }}>
                  <Nav
                    pills
                    className="flex-column justify-content-between navs_aside"
                  >
                    

                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Admins Setting
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "2",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        Dashboard Settings
                      </NavLink>
                    </NavItem>

                    
                  </Nav>
                </Sticky>
              </Col>
              <Col md="9">
                <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted table__content mt-4 mt-md-0">
                    <TabPane tabId="1">
                    <AdminSettings
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <DashboardSettings
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OtionsSettings

const navsStyle = {
  cursor: "pointer",
  borderRadius: 0,
}
