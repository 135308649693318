/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link, useHistory } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import "../../Components/specification.scss"




const AddAuthorFormResources = props => {
  let p = props
  const [name_en, setNameEn] = useState("")
  const [nameEnError, setNameEnError] = useState(null)
  const [positionEnError, setPositionEnError] = useState(null)
  const [nameArError, setNameArError] = useState(null)
  const [positionArError, setPositionArError] = useState(null)
  const [imageError, setImageError] = useState(null)
  const [image, setImage] = useState(null)
  const [name_ar, setNameAr] = useState("")
  const [positionEn, setPositionEn] = useState("")
  const [positionAr, setPositionAr] = useState("")
  const [isOpen, setisOpen] = useState(false)

  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const imageRef = useRef(null);


  function handleAcceptedFiles(files) {
    files.map(file =>
        Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: prettyBytes(file.size)
        })
    )
    setImage(files[0])
  }


  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      nameEn: name_en,
      nameAr: name_ar,
      positionEn: positionEn,
      positionAr: positionAr,
      id,
    }

    for (let key in data) {
      formData.append(key, data[key])
    }
    
    formData.append('image', image);
    
    let method = props.category? `/edit`: ``
    
    axios({
      method: 'POST',
      url:`${process.env.REACT_APP_API}/dashboard/blog/configurations/author${method}`,
      data: formData,
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        'Content-Type': 'application/json'
    }
    }).then(res => {
        props.getResourceData()
        toast.success(`Author  ${props.category?'Updated':"Added"} Success`, {position: "top-right"})
        setLoading(false)
        props.setActive(false)
        props.setCategory(null)
        props.getMain() 
      })
      .catch((error)=>{
        console.log(error.response.data.error);
          setLoading(false)
          if(error.response.data.error != null){
            if(error.response.data.error.positionEn != null){
              error.response.data.error.positionEn.map(error =>{
                setPositionEnError(error)
              })
            }
            if(error.response.data.error.nameEn != null){
              error.response.data.error.nameEn.map(error =>{
                setNameEnError(error)
              })
            }
          }
      })
    
  }

  useEffect(() => {
    if (p.category) {
      setNameEn(p.category.nameEn)
      setNameAr(p.category.nameAr)
      setPositionEn(p.category.positionEn)
      setPositionAr(p.category.positionAr)
      setId(p.category.id)
      setImage(p.category.image)
      return
    }
    
  }, [p.category])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      {isOpen ? (
            <Lightbox
                mainSrc={image.preview ? image.preview : image}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "450px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            Author
          </div>
          <button onClick={() => (props.setActive(false), props.setCategory(null))}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
          <Row className="mt-3">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Name (English)</Label>
                <Input
                  name="nameEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                  style={{ border: nameEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  nameEnError ? 
                  <span className="fs-6 text-danger">
                    { nameEnError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="">
                <Label htmlFor="productdesc">Name (Arabic)</Label>
                <Input
                  name="nameAr"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Positon (English)</Label>
                <Input
                  name="positionEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={positionEn}
                  onChange={e => setPositionEn(e.target.value)}
                  style={{ border: positionEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  positionEnError ? 
                  <span className="fs-6 text-danger">
                    { positionEnError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="">
                <Label htmlFor="productdesc">Positon (Arabic)</Label>
                <Input
                  name="positionAr"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={positionAr}
                  onChange={e => setPositionAr(e.target.value)}
                />
                
              </div>
            </Col>
          </Row>
          <Row>
                        <Col>
                            <div className="mb-3">
                                <Label htmlFor="metatitle">Author Image</Label>
                                <div className="mb-3" ref={imageRef}>
                                    <Input 
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleAcceptedFiles(Object.values(e.target.files), e.target.value = '')}
                                        style={{borderRadius: 0}}
                                    />
                                   {
                                    imageError ? 
                                    <span className="fs-6 text-danger">
                                      { imageError }
                                    </span> : ''
                                  }
                                </div>
                            </div>
                        </Col>
                        {image? <Row className="align-items-center" style={{paddingLeft: "14px", paddingRight: "0px"}}>
                            <Col className="col-auto">
                                <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={image.name ? image.name : ''}
                                src={image.name ? image.preview : image}
                                style={{objectFit: "cover"}}
                                />
                            </Col>
                            <Col>
                                <Link
                                to="#"
                                className="text-muted "
                                >
                                {image.name}
                                </Link>
                                <p className="mb-0">
                                <strong>{image.formattedSize}</strong>
                                </p>
                            </Col>
                            <Col className='p-0'>
                                <div className="image__options">
                                    <button className="p-0"  onClick={(e) => setisOpen(true)}><i className="far fa-eye  fs-5 p-0"></i></button>
                                    <button className='p-0' style={{color: "#ff3838"}} onClick={(e) => setImage(null)}><i className='bx bx-trash fs-4'></i></button>
                                </div>
                            </Col>
                        </Row>:null}
                    </Row>
          
          
          
          
          
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={() => (props.setActive(false), props.setCategory(null))}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
const imageStyle = {
  width: "60px",
  height: "60px",
  display: "block",
  objectFit: "contain",
}
const tdStyle = { 
  whiteSpace: "nowrap",
  verticalAlign: "middle"
}

export default AddAuthorFormResources

const modulesQuill = {
  toolbar: {
    container: [
      [{ "header": [1, 2, 3, 4, 5, 6, false] }],
      [{ "font": [] }],
      [{ "align": [] }],
      ["bold", "italic", "underline"],
      [{ "list": "ordered" }, { "list": "bullet" }, { "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", "custom-color"] }, { "background": [] }, "link", "emoji"]
    ],
    handlers: {
      "color": function(value) {
        if (value === "custom-color") value = window.prompt("Enter Hex Color Code")
        this.quill.format("color", value)
      }
    }
  },
  keyboard: {
    bindings: {
      tab: false,
      custom: {
        key: 13,
        shiftKey: true,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      },
      handleEnter: {
        key: 13,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      }
    }
  }
}

const formatsQuill = [
  "header", "font", "size",
  "bold", "italic", "underline", "strike", "blockquote",
  "list", "bullet", "indent", "align",
  "link", "image", "background", "color", "emoji"
]