import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import axios from "axios"
import { toast } from "react-toastify"

const WhatOffer = ({data}) => {
  const [rows2, setrows2] = useState([{ id: 1 }])
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [subTitleEn, setSubTitleEn] = useState("")
  const [subTitleAr, setSubTitleAr] = useState("")
  const [image, setImage] = useState(null)
  const [multiData, setMultiData] = useState([{id: 1,pointEn: '',pointAr: ''}])


  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)
    setMultiData(data => data.concat({id:modifiedRows.length,pointEn: '',pointAr: ''}))
  }

  function handleRemoveRow(id) {
    if (id !== 0) {
      console.log(rows2.filter((x,i) => i !== id), multiData.filter((x,i) => i !== id), id);
      setrows2(r => r.filter((x,i) => i !== id))
      setMultiData(d => d.filter((x,i) => i !== id))
    }
    axios.post(`${process.env.REACT_APP_API}/dashboard/learnUi/whatOffer/deletePoint`, {id: id+1}, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      },
    }).then(res => {
    })
  }

  function handleImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setImage(Object.values(files)[0])
    e.target.value = ""
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function pointsChange(e, key, type) {
    setMultiData(points =>
      points.map((data, index) => {
        if (index === key && type === "pointEn") {
          return { ...data, pointEn: e.target.value }
        }  else if (index === key && type === "pointAr") {
          return { ...data, pointAr: e.target.value }
        } 
        return data
      })
    )
  }
  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }


  function submit(e) {
    setActive(true)
    e.preventDefault()
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      subTitleEn,
      subTitleAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append("image", image)
    multiData.forEach((point, i) => {
      formData.append(`cards[${i}][pointEn]`, point.pointEn)
      formData.append(`cards[${i}][pointAr]`, point.pointAr)
    })
    axios.post(`${process.env.REACT_APP_API}/dashboard/learnUi/whatOffer`, formData, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      },
    }).then(res => {
      toast.success("Data Saved Successfully", {position: "top-right"})
      setActive(false)
    })
  }

  useEffect(() => {
    if(data) {
      setTitleEn(data.titleEn)
      setTitleAr(data.titleAr)
      setSubTitleEn(data.subTitleEn)
      setSubTitleAr(data.subTitleAr)
      setrows2(data.points)
      setMultiData(data.points)
      setImage(data?.image ? {preview: data.image} : null)
    }
    
  }, [data])

  return (
    <div>
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}

            <Form className="border-0">
              <Row>
                <Col xs="12">
                  <div className="mt-3">
                    <Label htmlFor="productdesc">Image Of Can Attend</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      onChange={e => handleImage(e, e.target.files)}
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
                <Col xs="12">
                  <div className="dropzone-previews" id="file-previews">
                    {image ? (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={image.name}
                                src={image.preview}
                                style={{ objectFit: "cover" }}
                              />
                            </Col>
                            <Col>
                              <Link to="#" className="text-muted ">
                                {image.name}
                              </Link>
                            </Col>
                            <Col>
                              <div className="image__options">
                                <button
                                  className="p-0"
                                  onClick={e =>
                                    prevSelectImage(e, image.preview)
                                  }
                                >
                                  <i className="far fa-eye  fs-5 p-0" />
                                </button>
                                <button
                                  style={{ color: "#ff3838" }}
                                  onClick={e => setImage(null)}
                                >
                                  <i className="bx bx-trash fs-4" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title Of Can Attend (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Title Attend"
                      value={titleEn}
                      onChange={e => setTitleEn(e.target.value)}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title Of Can Attend (arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={titleAr}
                      onChange={e => setTitleAr(e.target.value)}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Sub Title Of Can Attend (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title Attend"
                      value={subTitleEn}
                      onChange={e => setSubTitleEn(e.target.value)}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Sub Title Of Can Attend (arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={subTitleAr}
                      onChange={e => setSubTitleAr(e.target.value)}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "25px -20px 25px -20px" }} />
              {(rows2 || []).map((formRow, key) => (
                <Fragment key={key}>
                  <Row className="mt-3">
                    <Col sm="6">
                      <div>
                        <Label htmlFor="productdesc">Point (English)</Label>
                        <textarea
                          className="form-control  mb-0"
                          id="specification"
                          rows="2"
                          placeholder="Enter your description here"
                          value={multiData[key].pointEn}
                          onChange={e => pointsChange(e, key, "pointEn")}
                        />
                      </div>
                    </Col>
                    <Col sm="6">
                      <div>
                        <Label htmlFor="productdesc">Point (Arabic)</Label>
                        <textarea
                          className="form-control  mb-0"
                          id="specification"
                          rows="2"
                          placeholder="اكتب وصف هنا"
                          value={multiData[key].pointAr}
                          onChange={e => pointsChange(e, key, "pointAr")}
                          dir="rtl"
                        />
                      </div>
                    </Col>
                  </Row>
                  {key !== 0 ? (
                    <Button
                      color="none"
                      variant="primary"
                      className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                      id="unknown-btn"
                      style={{
                        color: "#ff3838",
                        boxShadow: "none",
                        height: "45px",
                        borderRadius: 0,
                      }}
                      onClick={e => {
                        handleRemoveRow(key)
                      }}
                    >
                      <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
                    </Button>
                  ) : null}
                  {
                    key!=0?
                    <hr style={{ margin: "5px -20px 20px -20px" }} />
                    :
                    <hr style={{ margin: "25px -20px 20px -20px" }} />
                  }
                </Fragment>
              ))}
              {/* <hr style={{ margin: "25px -20px 20px -20px" }} /> */}

              <Button
                onClick={() => {
                  handleAddRowNested()
                }}
                color="none"
                className="mt-2 fs-5 fw-bold p-0 text-left"
                style={btnStyle}
              >
                <i className="bx bx-plus-circle fs-4" /> <span>Add points</span>
              </Button>
              <hr style={{ margin: "20px -20px 20px -20px" }} />
            </Form>
      <Row>
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button onClick={e => submit(e)}>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default WhatOffer

const cardStyle = {
  borderTop: "2px solid #dedede",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
