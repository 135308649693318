/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle,Table } from "reactstrap"
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import ReactDragListView from 'react-drag-listview/lib/index.js';
import axiosConfig from "axiosConfig"
import AddResourcesFilterTag from "./Forms/AddResourcesFilterTag"
import { toast } from "react-toastify"


const TagFilterResources = (props) => {
  const [active, setActive] = useState(false)
  const[productData, setProductData] = useState([])
  const [data, setData] = useState([])
  const [id, setID] = useState(null)




  function deleteCategory(id) {
    axios.post(`${process.env.REACT_APP_API}/dashboard/configurations/resourceTags/delete`, {id}, {
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      }
    })
    .then(res => {
        toast.success('Tag Deleted Successfully', {theme: "light", position: "top-right"})
        getTags()
    }).catch(err=>{
    })
    }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
        const modifiedData = [...productData];
        const item = modifiedData.splice(fromIndex, 1)[0];
        modifiedData.splice(toIndex, 0, item);
        // setProductData(modifiedData);
        axios.post(`${process.env.REACT_APP_API}/dashboard/configurations/resourceTags/arrange`, {ids: modifiedData.map(cat =>cat.id)}, {
          headers: { 
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
          }
        })
        .then(res => {
          getTags()
        })
    },
    nodeSelector: 'tr',
    handleSelector: 'tr'
  };


  function getTags (){
    axiosConfig.get(`/dashboard/configurations/resourceTags`,{
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        }
    }).then(res => {
        setProductData(res.data.data.map(data => { return{...data
          // tag: <Toggle size="md" checked={data?.star == 1?true:false} onChange={(value) => {ShowItem(data?.id, data?.star)}}/>,
          // actions: <div className="d-flex">
          //               <button style={editBtn}  onClick={() => (setActive(true),setID(data.id))}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
          //               <button style={deleteBtn}  onClick={(e)=>deleteCategory(data.id)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>
          //         </div>
        }}))
    }).catch(err=>{
    console.log(err)
    })
}

useEffect(()=>{
  getTags()
},[])

const ShowItem = (itemID,status) => {
  console.log(status)
  axiosConfig.post(`${process.env.REACT_APP_API}/dashboard/configurations/resourceTags/star`, {
    'id' : itemID,
    star: !status
  },  {
    headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
  }
  })
  .then(res => {
    getTags()
  })
}

  return (
    <React.Fragment>
        <div className="container-fluid p-0">
           
              {active&&<AddResourcesFilterTag setActive={setActive} active={active} getData={getTags} idData={id} data={productData} setID={setID}/>}
              <Row>
                <Col className="col-12">
                  <Card >
                    <CardBody className="pb-4">
                        <Row >
                            <div className="save__btn top mb-2">
                                <button onClick={() => setActive(true)}><i className='bx bx-plus-circle fs-4' /> Add Tag</button>
                            </div>
                        </Row>
                        <div className="table-responsive">
                          <ReactDragListView {...dragProps}>
                              <Table className="table mb-0">
                                  <thead>
                                      <tr>
                                          <th>id</th>
                                          <th>Name English</th>
                                          <th>Name Arabic</th>
                                          <th>Color</th>
                                          <th># of the website</th>
                                          <th>ِActions</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {(productData || []).map((item, index) => (
                                          <tr key={index}>
                                              <td >{item.id}</td>
                                             
                                              <td>{item.titleEn}</td>
                                              <td>{item.titleAr}</td>
                                              <td>
                                                <span className='me-2' style={{backgroundColor: item.color, width: '25px',
                                                height: '25px',
                                                borderRadius: '50%',
                                                border: "1px solid #ccc",
                                                marginRight: "15px",
                                                display: 'inline-block'}}>
                                                </span>
                                              </td>
                                              <td>{item.PagesCount}</td>
                                              <td>{item.actions}</td>
                                          </tr>
                                      ))}
                                  </tbody>
                              </Table>
                          </ReactDragListView>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
        </div>
    </React.Fragment>
  )
}

export default TagFilterResources

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px"
}
const tdStyle = { 
  whiteSpace: "nowrap",
  verticalAlign: "middle"
}