import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Input, Label, Row} from 'reactstrap'
import ReactLoading from "react-loading"
import { closestCenter, DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import VideoForm from '../VideoForm/VideoForm'
import { toast } from 'react-toastify'
import axios from 'axios'




const Viedos = ({id, itemData, getData}) => {
  const [active, setActive] = useState(false)
  const [data, setData] = useState([])
  const [titleEn, setTitleEn] = useState(null)
  const [titleAr, setTitleAr] = useState(null)

  function handleAddRowNested() {
    setData(data => data.concat({ articleEn: "", articleAr: "", link: ""}))
  }

  function handleDrag(event) {
    const {active,over} =event
    if(active.id!=over.id){
      const activeIndex = active.data.current.sortable.index
      const overIndex = over.data.current.sortable.index
      const  newArrange = arrayMove(data,activeIndex,overIndex)
      const ids = newArrange.map(item => item.id?item.id:null)
      setData(data => newArrange)
      axios.post(`${process.env.REACT_APP_API}/dashboard/blog/videos/arrange`, {ids, blogID: id},{
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        toast.success(res.data.message);
      })
    }
  }

  function submit () {
    setActive(true)
    let formData = new FormData()

    data.forEach((item, i) => {
      let keys = Object.keys(item) 
      keys.forEach(key => {
        formData.append(`rows[${i}][${key}]`, item[key])
      })
    })
    formData.append('titleAr', titleAr)
    formData.append(`titleEn`, titleEn)
    formData.append(`blogID`, id)

    id?axios.post(`${process.env.REACT_APP_API}/dashboard/blog/videos`, formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
    .then(res => {
      setActive(false)
      toast.success(res.data.message);
      getData()
    }):toast.error(`Please Submit Step Information First 😒`)
  }
  
  useEffect(() => {
    if(itemData) { 
      if(itemData.Videos?.Cards != null){
        setData(itemData.Videos?.Cards);
      }
     
      setTitleEn(itemData.Videos?.titleEn);
      setTitleAr(itemData.Videos?.titleAr);
    }
  }, [itemData])

  return (
    <>
      <Card>
          <CardBody>
          <Row className="mt-3 mb-4">
                    <Col>
                        <div className="">
                            <Label htmlFor="productdesc">Title Section (English)</Label>
                            <Input
                                name="nameEn"
                                type="text"
                                className="form-control input_cus rounded"
                                placeholder="e.g.Name"
                                value={titleEn}
                                onChange={e => setTitleEn(e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="">
                            <Label htmlFor="productdesc">Title Section (Arabic)</Label>
                            <Input
                                name="nameEn"
                                type="text"
                                className="form-control  input_cus rounded"
                                placeholder="e.g.Name"
                                dir="rtl" 
                                value={titleAr}
                                onChange={e => setTitleAr(e.target.value)}
                            />
                        </div>
                    </Col>
                </Row>

          <DndContext onDragEnd={handleDrag} collisionDetection={closestCenter}>
                <SortableContext items={data.map((d,i) => i+1)}  strategy={verticalListSortingStrategy}>
                    { data.map((item, index) => {
                        return <VideoForm key={index} sortId={index+1} index={index} data={data[index]} setData={setData}/>
                    })}
                    <div className="">
                        <div className="add__btn top m-0 mt-2">
                            {<button onClick={handleAddRowNested} className="d-block pl-2 pr-2"><i
                            className="bx bx-plus-circle" /> Add Video 
                            </button>}
                        </div>
                    </div>
                </SortableContext>
              
            </DndContext>


              {/*************Submit button*************/}
              <hr style={{ margin: "20px -20px 20px -20px" }} />
              <Row>
                <Col className="d-flex justify-content-end gap-2">
                  <div className="save__btn top m-0 ">
                   <button type='button' onClick={submit}>
                      {active ? (
                        <ReactLoading
                          type={"spin"}
                          color={"#ffffff"}
                          height={30}
                          width={30}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
          </CardBody>
      </Card>
    </>
  )
}

export default Viedos