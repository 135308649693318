import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import AdminPagination from "./AdminPagination"
import { useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import "./configration.scss"
import "./datatables.scss"
import "./specification.scss"

const Subscription = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const sizePerPage = 10
  const [page, setPage] = useState(1)
  const [totalSize, setTotalSize] = useState("10")
  const [filter, setFilter] = useState(null)
  const [data, setData] = useState([])

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
    },

    {
      dataField: "countryCode",
      text: "Country Code",
      sort: true,
    },
    {
      dataField: "countryName",
      text: "Country (Engllish)",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
    },
  ]

  function deleteArticle(id) {
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/blog/configurations/subscription`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        toast.success(res.data.message)
        setData(data => data.filter(d => d.id !== id))
      })
  }

  useEffect(() => {
    dispatch(setTitle("Blog Subscriptions"))
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/blog/configurations/subscription`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setData(
          res.data.data.map(data => {
            return {
              ...data,
              countryCode: data?.country ? (
                data?.country.code
              ) : (
                <span className="fs-4">-</span>
              ),
              countryName: data?.country ? (
                data?.country.nameEn
              ) : (
                <span className="fs-4">-</span>
              ),
              country: "sdsd",
              actions: (
                <div className="d-flex">
                  <button
                    style={deleteBtn}
                    onClick={e => deleteArticle(data.id)}
                  >
                    <i
                      className="bx bx-trash fs-4"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Delete
                  </button>
                </div>
              ),
            }
          })
        )
      })
  }, [])

  return (
    <>
      <Card>
        <CardBody>sd</CardBody>
      </Card>
      <Card>
        <CardBody>
          <AdminPagination
            data={data}
            page={page}
            setFilter={setFilter}
            keyFiled="id"
            sizePerPage={sizePerPage}
            totalSize={totalSize}
            url=""
            none={false}
            onTableChange={(type, { page }) => {
              setPage(page)
            }}
            columns={columns}
          />
        </CardBody>
      </Card>
    </>
  )
}

export default Subscription

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const imageStyle = {
  width: "60px",
  height: "60px",
  display: "block",
  objectFit: "contain",
}
