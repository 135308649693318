import {
  GET_MAIN_DATA
} from "./actionTypes"

const INIT_STATE = {
  data: null,
}

const MainData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAIN_DATA:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default MainData